import React from 'react'
import { Button } from 'semantic-ui-react'
import UndoLine from './controls/line/undo'
import RedoLine from './controls/line/redo'
import Find from './controls/line/find'
import FindNext from './controls/line/find-next'
import FindPrev from './controls/line/find-prev'

const UndoBar = ({tristUrl}) => (<Button.Group>
    <UndoLine tristUrl={tristUrl} />
    <RedoLine tristUrl={tristUrl} />
</Button.Group>)

const FindBar = ({tristUrl}) => {
  return <Button.Group>
    <Find />
    <FindNext tristUrl={tristUrl} />
    <FindPrev tristUrl={tristUrl} />
  </Button.Group>
}

export default ({tristUrl}) => {
  return <div id='toolbar'>
    <FindBar     {...{ tristUrl }}/>
    <UndoBar     {...{ tristUrl }} />
  </div>
}
