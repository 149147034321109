import React from 'react'
import { connect } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { find } from '../../../../actions/actions'

//const Component = ({onClick}) => <Button onClick={onClick} icon='caret up'/>
const Component = ({onClick}) => <Popup content='Next match (up)' position='bottom left' trigger={<Button {...{onClick}} icon='caret up' />} />

const mapDispatchToProps = (dispatch, {tristUrl}) => ({ onClick : e => dispatch(find(tristUrl, true, false)) })

export default connect(null, mapDispatchToProps)(Component)

