import { extend } from 'lodash'
import { ReduxActions as RA } from '../constants'

//import { toJSON, toHtml, toText } from '../../../common/formats/linelib'

// function copy(state, action) { // need tristUrl and clipPusher to make this work
//   let {appvars} = state || {}
//   const {tristUrl, clipPusher} = action
//   if(tristUrl) {
//     const vtrist = state.vtrists.find(VT => tristUrl === VT.tristUrl)
//     const {trist} = vtrist
//     if(trist.focus) {
//       const copiedClip = trist.copy()
//       const showFields = false
//       // let {html, imageLinks} = RENDER(trystup, {showFields:false})
//       const html = toHtml(copiedClip, {format:'html', showFields, useStylesheets:false})
//       const text = toText(copiedClip, {format:'text', showFields})
//       const json = JSON.stringify(toJSON(copiedClip))
//       clipPusher(text, html, json)
//       appvars = extend({}, appvars, {lineRef:{tristUrl, lineId: trist.focus.id}})
//     }
//   }
//   return appvars
// }

const defaultAppVars = () => {
  return {
    username       : null,
    uid            : null,
    isAnon         : true,
    isMobile       : false,
    partialKey     : null,
    lineRef        : null,
    searchRegex    : null,
    tristLoadingIds: [],
    showWelcome    : true
  }
}

const buildRegex = searchTerm => {
  const escaped = searchTerm  // escaping is not working for some reason
  const options = (escaped === escaped.toLowerCase()) ? 'i' : ''
  return new RegExp(escaped, options)
}

//const setImage = (appvars, image) => extend({}, appvars, { image })  

function APPVARS(state, action) {
  let appvars = state.appvars || defaultAppVars()
  switch(action.type) {
  //case RA.SET_IMAGE:          return setImage(appvars, action.image)
  case RA.SET_INITIALSTATE:   return extend({}, appvars, action.initialState)
  // case RA.SET_PARTIALKEY:     return extend({}, appvars, { partialKey : appvars.partialKey ? null : action.keyCode } )  
  // case RA.SET_USERNAME:       return extend({}, appvars, { username   : action.username } )
  case RA.SET_FINDEXP:        return extend({}, appvars, { searchRegex: buildRegex(action.searchTerm) })
  // case RA.COPY_SELECTION:     return copy(state, action) 
  default: return appvars
  }
}

export default APPVARS