import _ from 'lodash'
import {/*ContentState, */convertFromRaw/*, EditorState */} from 'draft-js'

const RGXLINK = {
    INTERNAL: /^#[a-z0-9]+$/i,
    TRYSTAL: /^trystal:/i,
    IMAGE: /\.(gif|png|jpe?g)$/i
}
const LINKTYPES = { OTHER:1, INTERNAL:2, TRYSTAL:3, IMAGE:4 }
const LINKTEST = (p, s) => _.startsWith(s, '#[(', p) && s.indexOf(') ', p+3) > 0

const FORMULATEST = (s, p) => _.startsWith(s, '#[=', p) && s.indexOf(']', p+3) >= 0
const FORMATTEST = (s,p) => {
    if(!_.startsWith(s, '#[', p)) return false
    p += 2
    let spacePos = s.indexOf(' ', p) - p
    switch(spacePos) {
    case 1: return 'bisu'.indexOf(s[p]) >= 0
    case 2:
        switch(s[p]) {
        case 's': return '12345'.indexOf(s[p+1]) >= 0
        case 'f': return '012'.indexOf(s[p+1]) >= 0
        default: return false
        }
    case 3:
        if(s[p+1] !== 'g') return false
        switch(s[p]) {
        case 'f': return ['0', '2', '3', '4', '5'].includes(s[p+2]) > 0
        case 'b': return ['0', '1', '2', '3', '4', '5'].includes(s[p+2]) > 0
        default: return false
        }
        default: break
    }
    return false
}

const initBlockFromText = text => ({ text, type:'unstyled', inlineStyleRanges:[], entityRanges:[]})
function reduceBlocks(block1,block2) {
    const oldTextLength = block1.text.length
    block1.text += block2.text
    block2.inlineStyleRanges.forEach(isr => isr.offset += oldTextLength)
    block2.entityRanges.forEach(er  => er.offset  += oldTextLength)
    block1.inlineStyleRanges = [...block1.inlineStyleRanges, ...block2.inlineStyleRanges]
    block1.entityRanges      = [...block1.entityRanges,      ...block2.entityRanges]
    return block1
}
function renderChildren(childTokens, entityArray) {
    if(_.isEmpty(childTokens)) return initBlockFromText('')
    return childTokens
        .map(token => token.render(entityArray))
        .reduce(reduceBlocks) 
}
function appendEntity(entityArray, entity) {
    entityArray.push(entity)
    return (entityArray.length - 1).toString()
}

class Token {
    constructor(parent=null) {  
        this.parent = parent
        this.children = []
    }
    images() {
        const images = []
        this.children.forEach(token => images.push(...token.images()))
        return images
    }
    render(entityArray) { return renderChildren(this.children, entityArray) }
}
class TextToken extends Token {
    constructor(parent, startPos=0) {
        super(parent)
        this.startPos = startPos
        this.endPos = startPos
        this.str = null
    }
    close(str) {
        let s = str.substring(this.startPos, this.endPos + 1)
        s = s.replace(/\\([#\\\][])/g,'$1')  // convert escaped pound, backslash, and sqbraces into the chars
        this.str = s
    }
    render() { return initBlockFromText(this.str) }
}

const CODES = {
    b:   'BOLD', i:'ITALIC',   u:'UNDERLINE', s:'STRIKEOUT',
    bg0: 'BG0',  bg1:   'BG1', bg2: 'BG2', bg3:'BG3', bg4:'BG4', bg5:'BG5',
    fg0: 'FG0',  fg2:   'FG2', fg3: 'FG3', fg4:'FG4', fg5:'FG5', 
    f0:  'F0',   f1:    'F1',  f2:  'F2',
    s1:  'S1',   s2:    'S2',  s3:  'S3',  s4:'S4', s5:'S5'
}

class FormatToken extends Token {
    constructor(parent, str, p) {
        super(parent)
        this.format = str.substring(p, str.indexOf(' ', p)).toLowerCase()
    }
    render(entityArray) {
        const block = renderChildren(this.children, entityArray)
        const code = CODES[this.format]
        block.inlineStyleRanges.push({offset:0, length: block.text.length, style: code})
        return block
    }
}
class LinkToken extends Token {
    constructor(parent, str, pos) {
        super(parent)
        this.link = null
        this.linkType = null
        pos += 3
        let posEnd = str.indexOf(')', pos)
        let link = this.link = str.substring(pos, posEnd)
        if(RGXLINK.INTERNAL.test(link)) this.linkType = LINKTYPES.INTERNAL
        else if(RGXLINK.TRYSTAL.test(link)) this.linkType = LINKTYPES.TRYSTAL
        else if(RGXLINK.IMAGE.test(link)) this.linkType = LINKTYPES.IMAGE
        else this.linkType = LINKTYPES.OTHER
    }
    images() {
        let images = []
        if(this.linkType === LINKTYPES.IMAGE) images.push(this.link)
        this.children.forEach(token => images.push(...token.images()))
        return images
    } 
    // #[(abc)_
    render(entityArray) {
        const {link, children} = this
        const block = renderChildren(children, entityArray)
        const key = appendEntity(entityArray, {
            type:'LINK',
            mutability:'MUTABLE',
            data:{url:link}
        })
        const offset = 0
        const length = block.text.length
        block.entityRanges.push({offset, length, key})   // <==== what are we doing here
        return block
    }
}
class FormulaToken extends Token {
    constructor(parent, str, pos) {
        super(parent)
        pos += 3
        let posEnd = str.indexOf(']', pos)
        this.formula = str.substring(pos, posEnd)
    }
    // #[=abc_
    render(entityArray) {
        const {formula} = this
        const block = initBlockFromText(formula) //  renderChildren(children, entityArray)
        const key = appendEntity(entityArray, {
            type       : 'FIELD',
            mutability : 'IMMUTABLE',
            data       : {formula:formula}
        })
        const offset = 0
        const length = block.text.length 
        block.entityRanges.push({offset, length, key})   // <==== what are we doing here
        return block
    }
}
function tokenize(str) {
    const ROOT = new Token()
    const TEXTTOKENS = [] // keep a list of these so we can easily post process the content at the end
    const posMax = str.length
    let pos = 0, TOKEN = ROOT
    while(pos < posMax) {
        const oldpos = pos
        if(str[pos] === ']') {
            if(TOKEN instanceof TextToken) TOKEN = TOKEN.parent
            if(TOKEN.parent) TOKEN = TOKEN.parent
            pos++
        }
        else if(FORMATTEST(str, pos)) {
            if(TOKEN instanceof TextToken) TOKEN = TOKEN.parent
            const FORMAT = new FormatToken(TOKEN, str, pos+2)
            TOKEN.children.push(FORMAT)
            TOKEN = FORMAT
            pos += (FORMAT.format.length + 3)
        }
        else if(LINKTEST(pos, str)) {
            if(TOKEN instanceof TextToken) TOKEN = TOKEN.parent
            let LINK = new LinkToken(TOKEN, str, pos)
            TOKEN.children.push(LINK)
            TOKEN = LINK
            pos += LINK.link.length + 5
        }
        else if(FORMULATEST(str, pos)) {
            if(TOKEN instanceof TextToken) TOKEN = TOKEN.parent
            let FORMULA = new FormulaToken(TOKEN, str, pos)
            TOKEN.children.push(FORMULA)
            pos += FORMULA.formula.length + 4  // #[=TODAY]
        }
        else {
            if(!(TOKEN instanceof TextToken)) {
                let RAW = new TextToken(TOKEN, pos)
                TOKEN.children.push(RAW)
                TOKEN = RAW
                TEXTTOKENS.push(RAW)
            }
            let textToken = TOKEN
            if(str[pos] === '\\' && pos < posMax - 1) {
                textToken.endPos = pos + 1
                pos+=2
            } 
            else {
                textToken.endPos = pos
                pos++
            }
        }
        if(pos === oldpos) pos++
    }
    TEXTTOKENS.forEach(token => token.close(str))
    return ROOT
}

function renderDraftJS(trystup) {
    const root = tokenize(trystup)
    const entityArray = []
    const block = root.render(entityArray)
    const entityMap = entityArray.reduce((accum, entity, i) => {
        const key = i.toString()
        accum[key] = entity
        return accum
    }, {})
    const raw = {
        entityMap,
        blocks:[block]
    }
    const contentState = convertFromRaw(raw)  // merges the entities and raw blocks into contentBlocks
    // const imageLinks = root.images()
    return { contentState /*, imageLinks*/ }
}

export default renderDraftJS
