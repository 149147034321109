export class TristId {
  constructor(uid, filename) {
    this.uid = uid || null
    this.filename = filename || null
  }

  toString() {
    let {uid,filename} = this
    return `${uid}/${filename}`
  }

  isEqualTo(tristId) {
    if(!tristId) return false
    if(this.filename !== tristId.filename) return false
    if(this.uid !== tristId.uid) return false
    return true
  }
}
