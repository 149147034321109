import { ReduxActions as RA } from '../constants'

const defaultFindDialogState = {
    isVisible:false, 
    searchTerm:''
}

function findDialog(state=defaultFindDialogState,action) {
    switch(action.type) {
    case RA.DLG_FIND_SHOW:   return { searchTerm: '', isVisible: true    }
    case RA.DLG_FIND_UPDATE: return { searchTerm: action.searchTerm, isVisible: state.isVisible }
    case RA.DLG_FIND_CANCEL: return { searchTerm: null, isVisible:false }
    default: return state
    }
}

export default findDialog

