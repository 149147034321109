/* eslint no-extra-boolean-cast:0 */
import _ from 'lodash' 

//import LINE  from './line'
import Trist from  '@trystal/tryst/trist' 
import Node  from '@trystal/tryst/trist-node' 
import {buildFormatString} from './REV2014'
import {lineloLine} from './linelib'

class Helper {
    constructor(sline) {
        const {Id,Parent,Level} = sline
        this.id = Id
        this.pid = Parent || '_'
        this.level = Level || 0
        this.node = new Node(lineloLine(sline))
        this.PV = null
    }
}

export function toJSON(trist) {
    const nodes = trist.toArray()
    const lines = _.map(nodes, function (node) {
        const payload = node.payload
        const jsonline = {
            Id: payload.id,
            Format: buildFormatString(payload),
            Content: [{
                Created: 1416244527068,
                Content: payload.trystup
            }]
        }
        const level = node.level
        if (level) jsonline.Level = level
        const parent = node.parent
        if (!!parent) jsonline.Parent = parent.payload.id
        if (payload.link) jsonline.Link = payload.link
        return jsonline
    })
    const jsonTrist = {
        Authors: [],
        Lines: lines
    }
    return jsonTrist
}
export function fromJSON(serverTrist) {
    const slines = serverTrist.Lines
    let helpers = _.map(slines, sline => new Helper(sline))
    const parentIndex = _.keyBy(helpers, 'id')
    _.each(_.groupBy(helpers, 'pid'), children => {
        _.each(children,(child, index, arr) => {
            if (index === 0) {
                const pid = child.pid
                const parentLevel = pid === '_' ? 0 : parentIndex[pid].level
                child.node.rlevel = child.level - parentLevel
            }
            else {
                child.PV = arr[index - 1]
                child.node.PV = child.PV.node
                child.node.PV.NV = child.node
                child.node.rlevel = child.level - child.PV.level
            }
        })
    })

    helpers = _.where(helpers, h => h.id !== '_')
    const nodes = _.map(helpers, 'hug')
    _.each(nodes, function (node, index) {
        if (index > 0) node.prev = nodes[index - 1]
        if (index < nodes.length - 1) node.next = nodes[index + 1]
    })

    const trist = new Trist()
    trist.nodes = nodes
    trist.first = nodes[0]
    return trist
}
