import React from 'react'
import PropTypes     from 'prop-types'
//import keycode from 'keycode'
import { buildFormatClasses2 as getFormatClasses} from '../util/util'

import { keyCodeOf } from '@trystal/keys'
import { Editor, getDefaultKeyBinding, RichUtils } from 'draft-js'
import {
  isPartialKey, styleMap,
  getSimpleBinding, getCompoundBinding,
  addFieldReducer, addLinkReducer, setStyle,
  getLinkData
} from './editor/util'
//import {noticeEvent} from '../util/util'
import { setEditorState } from '../actions/actions'
const { toggleInlineStyle, handleKeyCommand} = RichUtils

function handleAddLinkRequest(editorState) {
  var url = getLinkData(editorState) || "http://"
  const linkURL = prompt('Enter a URL:', url)
  if (!linkURL) return null
  return addLinkReducer(editorState, linkURL)
}

function addInternalLink(editorState, rdxState, tristUrl) {
  const {lineRef} = rdxState.appvars
  if (lineRef.tristUrl !== tristUrl) return null
  return addLinkReducer(editorState, `#${lineRef.lineId}`)
}

const simpleStyles = [
  'BG0', 'BG1', 'BG2', 'BG3', 'BG4', 'BG5',
  'FG0', 'FG2', 'FG3', 'FG4', 'FG5',
  'F0', 'F1', 'F2',
  'S1', 'S2', 'S3', 'S4', 'S5']

  function reduceKeyCommand(props, rdxState, tristUrl, command, editorState) {
    if (simpleStyles.includes(command)) return setStyle(editorState, command)
  switch (command) {
    case 'do-nothing': return null
    case 'insert-field-1': return addFieldReducer(editorState, 'NOW')
    case 'insert-field-2': return addFieldReducer(editorState, 'TODAY')
    case 'insert-link': return handleAddLinkRequest(editorState)
    case 'insert-internal-link': return addInternalLink(editorState, rdxState, tristUrl)
    case 'strikethrough': return toggleInlineStyle(editorState, 'STRIKEOUT')
    default: return handleKeyCommand(editorState, command)
  }
}
function myKeyBindingFn(e) {
  e.stopPropagation()
  const keyCode = keyCodeOf(e)
  const partialKey = this.state.partialKey
  if (partialKey) {
    this.setState({ partialKey: null })
    return getCompoundBinding(partialKey, keyCode)
  }
  if (isPartialKey(keyCode)) {
    this.setState({ partialKey: keyCode })
    return 'do-nothing'
  }
  const binding = getSimpleBinding(keyCode)
  return binding ? binding : getDefaultKeyBinding(e)
}

class VisibleEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { partialKey: null }
    this.onChange = editorState => this.props.dispatch(setEditorState(editorState))
    this.handleKeyCommand = command => this._handleKeyCommand(command)
  }
  componentDidMount() {
    this.refs.editor.focus()    // ok.... but we need the cursor at the endEdit
  }
  componentDidUpdate() {
    this.refs.editor.focus()    // ok.... but we need the cursor at the endEdit
  }
  _handleKeyCommand(command) {
    const {tristUrl} = this.props
    const rdxState = this.context.store.getState()
    const editorState = this.props.editorState
    const newState = reduceKeyCommand(this.props, rdxState, tristUrl, command, editorState)
    if (!newState) return false
    this.onChange(newState)
    return true
  }
  render() {
    const {vline, onEndEdit, editorState} = this.props
    const {format} = vline
    const className = getFormatClasses(format)
    return <div className={className} onPaste={finish} onCopy={e=>copyFromEditor(e, editorState)} onKeyDown={stopProp}>
      <Editor
        ref='editor'
        editorState={editorState}
        onChange={this.onChange}
        keyBindingFn={myKeyBindingFn.bind(this) }
        handleKeyCommand={this.handleKeyCommand}
        onUpArrow={e => e.stopPropagation() }
        onDownArrow={e => e.stopPropagation() }
        handleReturn={onEndEdit}
        onEscape={onEndEdit}
        customStyleMap={styleMap}
        />
    </div>
  }
}

const stopProp = e => {
  e.stopPropagation()
}

const getSelectionText = editorState => {
  const selection = editorState.getSelection()
  const currentContent = editorState.getCurrentContent()
  const anchorKey = selection.getAnchorKey()
  const currentContentBlock = currentContent.getBlockForKey(anchorKey)
  const start = selection.getStartOffset()
  const end = selection.getEndOffset()
  return currentContentBlock.getText().slice(start, end)
}

const copyFromEditor = (e, editorState) => {
  const clipdata = e.clipboardData 
  clipdata.setData('text/plain', getSelectionText(editorState))
  e.preventDefault()
  e.stopPropagation()
  // clipdata.setData('text/html', html)
  // clipdata.setData('application/x-trystal.tryst+json', json) // old school json for backward compatibility
}

const finish = e => {
  e.stopPropagation()
  e.preventDefault()
}
VisibleEditor.contextTypes = {
  store: PropTypes.object
}

export default VisibleEditor

// Parked Code
    //const {imageLink} = vline
    // <div>{buildImageTag(imageLink)}</div>
    // const buildImageTag = (imageLink) => {
    //     if(!imageLink || !imageLink.length) return null
    //     return <div className='IMG'><img src={imageLink}/></div>
    // }


