import {each} from 'lodash'
const fgs = ['black', null, 'blue', 'green', 'red', 'gray']
const bgs = [null, 'yellow', 'powderblue', 'palegreen', 'mistyrose', 'lightgray']
const sizes = [null, 10, 13, 18, 24, 32]
const faces = ['serif', 'sans-serif', 'monospace']
const combos = (() => {
    const combos = {}
    each([0, 1, 2, 3, 4, 5], (bg, bgi) => {
        each([0, 2, 3, 4, 5], (fg, fgi) => {
            const c = '123456789abcdefghijklmnopqrstu'.charAt(bgi * 5 + fgi)
            combos[c] = { bg:bgs[bg], fg:fgs[fg] }
        })
    })
    return combos
})()

const spanIt = (csstag,value,content) => `<span style='${csstag}:${value}'>${content}</span>`

export function renderFormatAsHtml(format, content, options) {
  const {useStylesheets} = options
  switch(format) {
    case 'b': return `<strong>${content}</strong>`
    case 'i': return `<em>${content}</em>`
    case 's': return `<del>${content}</del>`
    case 'u': return `<ins>${content}</ins>`
    default: break
  }
  if(useStylesheets) return `<span class='${format}'>${content}</span>` 
  if(/^s[1-5]$/i.test(format))    return spanIt('font-size',        sizes[format[1]]+'px', content)
  if(/^f[0-2]$/i.test(format))    return spanIt('font-family',      faces[format[1]],      content)
  if(/^bg[0-5]$/i.test(format))   return spanIt('background-color', bgs[format[2]],        content)
  if(/^fg[02345]$/i.test(format)) return spanIt('color',            fgs[format[2]],        content) 
  if(/^c[0-9a-z]$/i.test(format)) {
    const {fg,bg} = combos[format[1]]
    return `<span style='background-color:${bg},color:${fg}'>${content}</span>`
  }
  return content
}
