// // import { Family, Perms } from '../constants' 
import { ViewName } from '../util/tryurl'
import { tristFromJSON } from '../formats/linelib'
import {
  setTimeline,
//     ajaxLoadTristStart,
//     ajaxLoadTristEnd,
//     ajaxLoadTristError,
  activateVTrist,
  appendVTrist,
  setTitle
//     setPublications
} from './actions'
import SELECT from '../select'

export const ajaxLoadLinelo = href => (dispatch, getState) => {
  fetch(href)
    .then(resp => resp.json())
    .then(tristFromJSON) 
    .then(trist => {
//      dispatch(appendVTrist(trist, href, isEditable))
    })
    .catch(err => alert(err))
}

// const fetchOptions = (method) => ({
//   method,
//   credentials:  'include',
//   headers:      new Headers({'Content-Type': 'application/json'}),
//   body:         JSON.stringify(revise(null, trist, 'anon'))
// })

export const ajaxLoadTrist = (turl, isBot) => async function(dispatch, getState) {
  const state = getState()
  const {view, tristId, tristUrl, ro, url} = turl  // view: published or edit
  if(SELECT.isOpenTristUrl(state, tristUrl)) return dispatch(activateVTrist(tristUrl))
  if(SELECT.isOpeningTristUrl(state, tristUrl)) return
  //dispatch(ajaxLoadTristStart(tristUrl))
  const {uid,filename} = tristId
  try {
    const response = await fetch(`https://trystal.net/api/1.0/trist/${uid}/${filename}/${ViewName(view)}`, {credentials:'omit'})
    const jsonTrist = await response.json()
//       dispatch(ajaxLoadTristEnd(tristUrl))
    const trist = tristFromJSON(jsonTrist) 
    const isEditable = !ro
    const visibleOnly =!isBot

    const response2 = await fetch('https://trystal.net/api/1.0/timeline')
    const timeline = await response2.json()
    const userTimeline = timeline.filter(item => item.uid === uid)
    const fileEntry = userTimeline.find(item => item.filename === filename)
    console.log('timeline is ', userTimeline)
    console.log('fileEntry is ', fileEntry)
    const {description, image, published, title, twitter, username} = fileEntry
    dispatch(appendVTrist(trist, tristId, isEditable, tristUrl, url, visibleOnly))
    dispatch(activateVTrist(tristUrl))
    dispatch(setTitle(title))
  }
  catch(err) {
    //dispatch(ajaxLoadTristError(tristUrl))
  }
}

export const ajaxLoadTimeline = () => dispatch => {
  fetch('https://trystal.net/api/1.0/timeline')
  .then(resp => resp.json())
  .then(timeline => dispatch(setTimeline(timeline))) 
  .catch(err => alert('did not load timeline'))
}

