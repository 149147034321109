import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { Portal, Segment, Header, Button, Icon} from 'semantic-ui-react'

const LeftIcon = () => <Icon name='arrow circle left' />
const RightIcon = () => <Icon name='arrow circle right' />
const UpIcon = () => <Icon name='arrow circle up fitted' />
const DownIcon = () => <Icon name='arrow circle down fitted' />

const HomepageHelp = () => 
<Fragment>
  <Header>Welcome to Trystalite</Header>
  <p>Trystalite documents are structured as outlines to make them easy to navigate.</p>
  <p>The documents listed here are the ones most recently updated by our content creators.</p>
  <p>Click the name of any document to access it.</p>
</Fragment>

const TrystPageHelp = () => <Fragment>
  <Header>Navigating documents</Header>
  <p>Click any line to select it</p>
  <p><UpIcon /> and <DownIcon /> change the selection</p>
  <p><LeftIcon /> and <RightIcon/> keys expand or collapse the selected line</p>
  <p>Also double-click lines also toggles expansion</p>
</Fragment>

const Helper = ({pageType}) => {
  switch(pageType) {
    case 2: return <TrystPageHelp />
    default: return <HomepageHelp />
  }
}

class Component extends React.Component {
  state = {open: false}
  handleClose = () => this.setState({ open: false })
  handleOpen = () => this.setState({ open: true })
  render() {
    const {pageType} = this.props
    const {open} = this.state
    return <Fragment>
      {/* <button class='ui circular icon button inverted' disabled={open} onClick={this.handleOpen} style={{margin:3}}><i class='yellow help icon'/></button> */}
      <button class='ui circular icon button inverted' disabled={open} onClick={this.handleOpen} style={{margin:3}}><i class='yellow help icon'/></button>
      <Portal onClose={this.handleClose} open={open}>
        <Segment style={{ left: '40%', position: 'fixed', top: '25%', zIndex: 1000, }} >
          <Helper pageType={pageType} />
          <Button content='I got it!' negative onClick={this.handleClose} />
        </Segment>
      </Portal>
    </Fragment>      
  }
}

const toPropsFromState = state => {
  const {pageType} = state.activePage
  return {pageType}
}

const ConnectedComponent = connect(toPropsFromState, null)(Component)

export  default ConnectedComponent