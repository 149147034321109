import React from 'react'
import { connect } from 'react-redux'
import { Input, Modal, Button } from 'semantic-ui-react'

import { find, dlgFindUpdate, dlgFindCancel, setFindExp } from '../../actions/actions'

function handleKeyDown(e, isValid, onOk, searchTerm) {
    if(e.which !== 13) return
    if(!isValid) return
    onOk(searchTerm)
}

const mapStateToProps = ({DLG_FIND:{isVisible, searchTerm}}) => {
  const isValid = !!searchTerm && searchTerm.length > 0
  return { isVisible, searchTerm, isValid }
}

const mapDispatchToProps = (dispatch, {tristUrl}) => {
  return {
    onOk:     (searchTerm) => {
      dispatch(setFindExp(searchTerm))
      dispatch(dlgFindCancel())
      dispatch(find(tristUrl, false, false))
    },
    onChange: searchTerm => dispatch(dlgFindUpdate(searchTerm)),
    onCancel: () => dispatch(dlgFindCancel())
  }
}

const TheInput = ({inputRef, searchTerm, onChange, isValid, onOk}) => <Input 
  ref={inputRef}
  placeholder='what to find' 
  value={searchTerm} 
  onChange={(e,data) => onChange(data.value)} 
  onKeyDown={e => handleKeyDown(e, isValid, onOk, searchTerm)}
  icon='search'
  fluid
/>

class JSX extends React.Component {
  constructor() {
    super()
    this.inputElement = null
  }
  componentDidUpdate(prevProps) {
    if(!prevProps.isVisible && this.props.isVisible) this.inputElement.focus()
  }
  render() {
    const {isVisible, searchTerm, isValid, onChange, onCancel, onOk} = this.props 
    return <Modal open={isVisible} onClose={onCancel} size='mini'>
      <Modal.Header>Find</Modal.Header>
      <Modal.Content style={{marginLeft:0}}>
        <TheInput inputRef={el => this.inputElement=el} {...{searchTerm, isValid, onOk, onChange}} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={e => onOk(searchTerm)} disabled={!isValid}>Ok</Button>
      </Modal.Actions>
    </Modal>
  }
}

export default connect( mapStateToProps, mapDispatchToProps )(JSX)
