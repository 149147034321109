import { ReduxActions as RA } from '../constants'
import VTRIST, {buildVisibleTrist} from './vtrist'

export default (state, action) => {
  const vtrists = state.vtrists || []
  switch(action.type) {
    case RA.CLOSE_VTRIST:      return vtrists.filter(vt => !(vt.tristUrl === action.tristUrl) )
    case RA.VTRIST_ACTION:     return vtrists.map(vtrist => VTRIST(state, vtrist, action))
    case 'APPEND_VTRIST':     return [...vtrists, buildVisibleTrist(action)]
    default: return vtrists
  }
}

