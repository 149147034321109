/*eslint react/no-danger:0 */
import $ from 'jquery'
import React from 'react'
import { placeCaretAtEnd, buildFormatClasses2 as getFormatClasses} from '../util/util' // '../../../shared/util'
import PropTypes     from 'prop-types'


const toContent = html => html && html.trim() !== '' ? html : '&nbsp;' // gets paste to work on the last line

class VisibleContent extends React.Component {
  componentDidMount() {
    const {vline:{id,isFocus}} = this.props
    if(!isFocus) return
    const $E = $(`#${id} .CNT`) 
    $E.focus()
    placeCaretAtEnd($E[0])
  }
  componentDidUpdate() {
    const {vline:{id,isFocus}} = this.props
    if(!isFocus) return
    const $E = $(`#${id} .CNT`) 
    $E.focus()
    placeCaretAtEnd($E[0])
  }
  render() {
    const {vline:{html,format,isEditing/*, isFocus*/}} = this.props
    const buildDangerousHTML      = () => ({ __html:toContent(html)})
    const ref                     = 'content' 
    const style                   = {cursor:isEditing ? 'auto' : 'default'}
    // if(isFocus && !isEditing) style.fontSize = '9px' 
    const tabIndex                = -1 
    const contentEditable         = isEditing 
    const className               = getFormatClasses(format)
    return <div {...{ref,style,tabIndex,contentEditable,className}} dangerouslySetInnerHTML={buildDangerousHTML()} />
  }
}
VisibleContent.propTypes = {
  vline : PropTypes.object     
}
export default VisibleContent
