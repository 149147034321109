import React from 'react'
import { connect } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { dlgFindShow } from '../../../../actions/actions'

//const Component = ({onClick}) => <Button {...{onClick}} icon='search'/>


const Component = ({onClick}) => <Popup content='Find (CTRL-F)' position='bottom left' trigger={<Button {...{onClick}} icon='search' />} />

const mapDispatchToProps = dispatch => ({ onClick : e => dispatch(dlgFindShow()) })

export default connect(null, mapDispatchToProps)(Component)

