import { KeyCodes as KC } from '@trystal/keys'
import { /*Entity, */Modifier, EditorState, RichUtils } from 'draft-js'
import { BGS, FGS, FACES } from '../../constants'
const {removeInlineStyle} = Modifier
const {toggleInlineStyle} = RichUtils

export const bindings = keyCode => keyCode === KC.CTRLK ? 'strikethrough' : undefined
export const isPartialKey = keyCode => [KC.CTRL1, KC.CTRLA, KC.CTRLS, KC.CTRLF, KC.CTRLH].indexOf(keyCode) >= 0
export function getSimpleBinding(keyCode) {
  switch (keyCode) {
    case KC.CTRLK: return 'strikethrough'
    case KC.CTRLL: return 'insert-link'
    case KC.CTRLSHIFTV: return 'insert-internal-link'
    default: return null
  }
}
export function getCompoundBinding(partialKey, keyCode) {
  switch (partialKey) {
    case KC.CTRL1:
      switch (keyCode) {
        case KC.ONE: return 'insert-field-1'
        case KC.TWO: return 'insert-field-2'
        default: break
      }
      break
    case KC.CTRLA:
      switch (keyCode) {
        case KC.ONE: return 'F0'
        case KC.TWO: return 'F1'
        case KC.THREE: return 'F2'
        default: break
      }
      break
    case KC.CTRLS:
      switch (keyCode) {
        case KC.ONE: return 'S1'
        case KC.TWO: return 'S2'
        case KC.THREE: return 'S3'
        case KC.FOUR: return 'S4'
        case KC.FIVE: return 'S5'
        default: break
      }
      break
    case KC.CTRLF:
      switch (keyCode) {
        case KC.ZERO: return 'FG0'
        case KC.TWO: return 'FG2'
        case KC.THREE: return 'FG3'
        case KC.FOUR: return 'FG4'
        case KC.FIVE: return 'FG5'
        default: break
      }
      break
    case KC.CTRLH:
      switch (keyCode) {
        case KC.ZERO: return 'BG0'
        case KC.ONE: return 'BG1'
        case KC.TWO: return 'BG2'
        case KC.THREE: return 'BG3'
        case KC.FOUR: return 'BG4'
        case KC.FIVE: return 'BG5'
        default: break
      }
      break
    default: break
  }
  return 'do-nothing'
}
export const styleMap = {
  STRIKEOUT: { textDecoration: 'line-through' },
  FG0: { color: 'black' },
  FG2: { color: FGS[2] },
  FG3: { color: FGS[3] },
  FG4: { color: FGS[4] },
  FG5: { color: FGS[5] },
  BG0: { backgroundColor: null },
  BG1: { backgroundColor: BGS[1] },
  BG2: { backgroundColor: BGS[2] },
  BG3: { backgroundColor: BGS[3] },
  BG4: { backgroundColor: BGS[4] },
  BG5: { backgroundColor: BGS[5] },
  F0: { fontFamily: FACES[0] },
  F1: { fontFamily: FACES[1] },
  F2: { fontFamily: FACES[2] },
  S1: { fontSize: '16px' },
  S2: { fontSize: '21px' },
  S3: { fontSize: '24px' },
  S4: { fontSize: '30px' },
  S5: { fontSize: '36px' }
}

export const addFieldReducer = (state, formula) => { // cmd === field-0 or field-1 ??
  const contentState1 = state.getCurrentContent().createEntity('FIELD', 'IMMUTABLE', { formula });
  const key = contentState1.getLastCreatedEntityKey();
  const contentState2 = Modifier.insertText(contentState1, state.getSelection(), formula, null, key)
  return EditorState.push(state, contentState2, 'apply-entity')
}
export const addDefaultText = (editorState, defaultText) => {
  let selectionState = editorState.getSelection()
  if(!selectionState.isCollapsed()) return editorState
  const contentState = Modifier.insertText(editorState.getCurrentContent(), selectionState, defaultText + ' ')
  editorState = EditorState.push(editorState, contentState, 'insert-characters')
  selectionState = selectionState.set('focusOffset',selectionState.getFocusOffset() + defaultText.length)
  editorState = EditorState.acceptSelection(editorState, selectionState)
  return editorState
}
// const urlFromEntity = entity => {
//   if(!entity || entity.type !== 'LINK') return null
//   return entity.getData().url
// }
// const selectionEnd   = selection => ({blockId:selection.getEndKey(),   offset:selection.getEndOffset()})
const selectionStart = selection => ({blockId:selection.getStartKey(), offset:selection.getStartOffset()})
const entityKeyAt = (editorState, {blockId, offset}) => editorState.getCurrentContent().getBlockForKey(blockId).getEntityAt(offset)
const entityAt = editorState => {
  const entityKey = entityKeyAt(editorState, selectionStart(editorState.getSelection())) 
  return entityKey ? editorState.getCurrentContent().getEntity(entityKey) : null
}
const linkEntityAt = editorState => {
  const entity = entityAt(editorState)
  return (entity && entity.type === 'LINK') ? entity : null
}
const urlAtCursor = editorState => {
  const linkEntity = linkEntityAt(editorState) 
  return linkEntity ? linkEntity.getData().url : null
}
const isCollapsed = editorState => editorState.getSelection().isCollapsed()
// const getLinkFromPoint = (editorState) => {
//   if(isCollapsed(editorState)) return null
//   return urlAtCursor(editorState)
// }
const blockAtSelectionStart = editorState => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey())
export const getLinkData = (editorState) => {
  if(isCollapsed(editorState)) return urlAtCursor(editorState)
  if(!RichUtils.currentBlockContainsLink(editorState)) return null

  const linkEntityKey = blockAtSelectionStart(editorState).getEntityAt(editorState.getSelection().getStartOffset())
  return editorState.getCurrentContent().getEntity(linkEntityKey).getData().url
}
export const updateLink = (editorState, urlValue) => {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startKey = selection.getStartKey()
  const startOffset = selection.getStartOffset()
  const block = contentState.getBlockForKey(startKey)
  const linkKey = block.getEntityAt(startOffset)
  //let url = ''
  //if(linkKey) {
    //const linkInstance = contentState.getEntity(linkKey)
    // url = linkInstance.getData().url
  const contentState2 = contentState.replaceEntityData(linkKey, {url:urlValue})
  return EditorState.push(editorState, contentState2, 'apply-entity')
  //}
}
export const addLinkReducer = (editorState, urlValue) => {
  if(RichUtils.currentBlockContainsLink(editorState)) return updateLink(editorState, urlValue) 
  if(isCollapsed(editorState)) {
    if(linkEntityAt(editorState)) return updateLink(editorState, urlValue) 
    const defaultText = new URL(urlValue).hostname || urlValue || 'link'
    editorState = addDefaultText(editorState, defaultText)
  }
  const contentState = editorState.getCurrentContent().createEntity('LINK', 'MUTABLE', {url:urlValue})
  return RichUtils.toggleLink(editorState, editorState.getSelection(), contentState.getLastCreatedEntityKey())
}
const BGCODES = ['BG0', 'BG1', 'BG2', 'BG3', 'BG4', 'BG5']
const FGCODES = ['FG0', 'FG2', 'FG3', 'FG4', 'FG5']
const SIZES = ['S1', 'S2', 'S3', 'S4', 'S5']
const FAMS = ['F0', 'F1', 'F2']
function getEm(format) {
  if (/FG./.test(format)) return FGCODES
  if (/BG./.test(format)) return BGCODES
  if (/S./.test(format)) return SIZES
  if (/F./.test(format)) return FAMS
}
export const setStyle = (state, style) => {
  const REMOVES = getEm(style)
  const selection = state.getSelection()
  const nextContentState = REMOVES.reduce(
    (contentState, style) => removeInlineStyle(contentState, selection, style),
    state.getCurrentContent()
  )
  let newState = EditorState.push(state, nextContentState, 'change-inline-style')
  const currentStyle = state.getCurrentInlineStyle()
  if (selection.isCollapsed()) newState = currentStyle.reduce((state, color) => toggleInlineStyle(state, color), newState)
  if (!currentStyle.has(style)) newState = toggleInlineStyle(newState, style)
  return newState
}
export const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity()
      return entityKey && contentState.getEntity(entityKey).getType() === 'LINK'
    },
    callback
  )
}
// deleteme
export const findFieldEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'FIELD'
      )
    },
    callback
  )
}

