import React from 'react'
import { connect } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { undo } from '../../../../actions/actions'

const Component = ({disabled, onClick}) => <Popup content='Undo (ctrl-z)' position='bottom right' trigger={<Button icon='undo' {...{disabled, onClick}} />} />

const mapStateToProps = ({vtrists}, {tristUrl}) => ({ disabled: !(vtrists.find(vt => vt.tristUrl === tristUrl)).canUndo })
const mapDispatchToProps = (dispatch, {tristUrl}) => ({ onClick:() => { dispatch(undo(tristUrl)) }})

export default connect(mapStateToProps, mapDispatchToProps)(Component)



