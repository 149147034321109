import React from 'react'
import { connect } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { find } from '../../../../actions/actions'

const Component = ({onClick}) => <Popup content='Next match (F3)' position='bottom left' trigger={<Button {...{onClick}} icon='caret down' />} />
const mapDispatchToProps = (dispatch, {tristUrl}) => ({ onClick : e => dispatch(find(tristUrl, false, false)) })

export default connect(null, mapDispatchToProps)(Component)

