import React from 'react'
import {Link} from 'react-router-dom'
import HelpWidget from '../help'
import { Menu } from 'semantic-ui-react'

export default () => <Menu inverted fixed='top' id='top-nav'>
  <Menu.Item name='trystalite' header as={Link} to='/'>Trystalite</Menu.Item>
  <Menu.Item position='right'><HelpWidget /></Menu.Item>
</Menu>


