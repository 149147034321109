//region Support Functions
const matchesTristUrl = (item, tristUrl) => item && item.tristUrl && item.tristUrl === tristUrl
const containsTristByTristUrl = (vtrists = [], tristUrl) => vtrists.some(vt => matchesTristUrl(vt, tristUrl))
//endregion

const getLoadingTristIds      = state => state.async || []
const getOpenTrists           = state => state.vtrists
const isOpenTristUrl          = (state, tristUrl) => containsTristByTristUrl(getOpenTrists(state), tristUrl)
const isOpeningTristUrl       = (state, tristUrl) => getLoadingTristIds(state).indexOf(tristUrl) >= 0
const isOpenOrOpeningTristUrl = (state, tristUrl) => isOpenTristUrl(state) || isOpeningTristUrl(state)

module.exports = {
    getLoadingTristIds, getOpenTrists,
    isOpenTristUrl, isOpeningTristUrl, isOpenOrOpeningTristUrl
}
