import { extend } from 'lodash'
import { 
    ReduxActions as RA,
    VisibleTristActions as VTA
} from '../constants' 
// import { KeyCodes } from '../keys'
// import { Trist } from '../trist'
// import { Line } from '../line'
// import { Family, Perms } from '../constants' 

// export function onCopyLines(focusId:string)       { return { type: RA.LINES_COPIED,  focusId }}

const vtristAction = (tristUrl, vtristActionType, action={}) => extend(action, {type:RA.VTRIST_ACTION, tristUrl, vtristActionType})

export const copySelection   = (tristUrl, clipPusher) => ({type:RA.COPY_SELECTION, tristUrl, clipPusher})
export const setEditorState  = editorState => ({type: RA.SET_EDIT_STATE, editorState})

// vtrist actions
export const blur            = tristUrl => vtristAction(tristUrl, VTA.BLUR)
export const extendDown      = tristUrl => vtristAction(tristUrl, VTA.EXTEND_DOWN)
export const extendUp        = tristUrl => vtristAction(tristUrl, VTA.EXTEND_UP)
export const undo            = tristUrl => vtristAction(tristUrl, VTA.UNDO)
export const redo            = tristUrl => vtristAction(tristUrl, VTA.REDO)
export const lineUp          = tristUrl => vtristAction(tristUrl, VTA.UP)
export const lineDown        = tristUrl => vtristAction(tristUrl, VTA.DOWN)
export const lineHome        = tristUrl => vtristAction(tristUrl, VTA.HOME)
export const lineEnd         = tristUrl => vtristAction(tristUrl, VTA.END)
export const lineLeft        = tristUrl => vtristAction(tristUrl, VTA.LEFT)
export const lineRight       = tristUrl => vtristAction(tristUrl, VTA.RIGHT)
export const lineOpen        = tristUrl => vtristAction(tristUrl, VTA.OPEN)
export const lineClose       = tristUrl => vtristAction(tristUrl, VTA.CLOSE)
export const setImageIndex   = (tristUrl, imageIndex)          => vtristAction(tristUrl, VTA.SET_IMAGEINDEX, {imageIndex}) 
export const setImage        = (tristUrl, image)               => vtristAction(tristUrl, VTA.SET_IMAGE,  {image})
export const setFocusToLine  = (tristUrl, focusId)             => vtristAction(tristUrl, VTA.SET_FOCUS,  {focusId})
export const find            = (tristUrl, searchUp, fromStart) => vtristAction(tristUrl, VTA.FIND,  {searchUp, fromStart})

export const replaceTrist    = (tristUrl, trist) => vtristAction(tristUrl, VTA.REPLACE_TRIST, {trist})
export const refreshVTrist   = tristUrl          => vtristAction(tristUrl, VTA.REFRESH_VTRIST)

// other actions
export const showFindbar        = ()           => ({ type: RA.FINDBAR_SHOW}) 
export const hideFindbar        = ()           => ({ type: RA.FINDBAR_HIDE}) 
export const hideSnackbar       = ()           => ({ type: RA.HIDE_SNACK})
export const showSnackbar       = message      => ({ type: RA.SHOW_SNACK, message})
export const setFindExp         = searchTerm   => ({ type: RA.SET_FINDEXP, searchTerm })  // mobile
export const setTimeline        = timeline     => ({ type: RA.SET_TIMELINE, timeline })
export const setPartialKey      = keyCode      => ({ type: RA.SET_PARTIALKEY, keyCode })
export const special            = ()           => ({ type: 'SPECIAL' }) 
export const showFilePicker     = ()           => ({ type: RA.FILEPICKER_SHOW })

export const showLeftNav        = ()           => ({ type: RA.LEFTNAV_SHOW})
export const hideLeftNav        = ()           => ({ type: RA.LEFTNAV_HIDE}) 
export const setInitialState    = initialState => ({ type: RA.SET_INITIALSTATE, initialState})


export const appendVTrist       = (trist, tristId, isEditable, tristUrl, url, visibleOnly=true) => ({ type: 'APPEND_VTRIST',  trist, tristId, isEditable, tristUrl, url, visibleOnly})

export const activateVTrist     = tristUrl      => ({ type: 'ACTIVATE_VTRIST', tristUrl })
export const closeVTrist        = tristUrl      => ({ type: 'CLOSE_VTRIST',    tristUrl })
export const setTitle           = title         => ({ type: 'SET_TITLE',    title })

export const ajaxLoadTristStart = tristUrl      => ({ type: 'TRIST_LOAD_START', tristUrl })
export const ajaxLoadTristEnd   = tristUrl      => ({ type: 'TRIST_LOAD_END',   tristUrl })
export const ajaxLoadTristError = tristUrl      => ({ type: 'TRIST_LOAD_ERROR', tristUrl })
  
export const dlgFindShow        = (tristUrl)    => ({ type: RA.DLG_FIND_SHOW, tristUrl } )
export const dlgFindUpdate      = (searchTerm)  => ({ type: RA.DLG_FIND_UPDATE, searchTerm } )
export const dlgFindCancel      = ( )           => ({ type: RA.DLG_FIND_CANCEL } )

