import {extend} from 'lodash'
// import { TryUrl, /* Views, */ ViewName } from '../../common/tryurl'

import dlgFindReducer     from './dlg-find'
// import dlgPublishReducer  from './reducers/dlg-publish'
// import dlgOpenfileReducer from './reducers/dlg-openfile'
// import dlgNewfileReducer  from './reducers/dlg-newfile'
// import dlgUsernameReducer from './reducers/dlg-username'
// import dlgShareReducer    from './reducers/dlg-share'
import VTRISTS            from './vtrists'
import EDITOR             from './editor'
// import MOBILEEDITOR       from './reducers/editor-mobile'
import APPVARS            from './appvars'
// import MOBILEVARS         from './reducers/appvars-mobile'
// import SNACKBAR           from './reducers/snackbar'
import TIMELINE           from './timeline'
import ACTIVEPAGE         from './active-page'
// import ASYNC              from './reducers/async'
// import SHARING            from './reducers/sharing'
// import TRISTREFS          from './reducers/tristrefs'

const defaultState = {
  activeElement:'app',
  templates:null,
  tristId: null,
  searchTerm:null,
  MOBILE_activeGadgetPane: null,
  MOBILE_gadgetsHidden:true
}

// const setPublications = (state, {publications}) => Object.assign({}, state, {publications})

// const updatePublication = (state, action) => {
//   // sometimes we are adding a publication, sometimes we are updating; still, focus on the update  for now
//   const {publications} = state
//   const {tristUrl, title} = action
//   const filename = TryUrl.UIDFN(tristUrl).filename
//   const publication = publications[tristUrl] || {}
//   const revision = Object.assign({}, publication, {filename, title})
//   const newPublications = Object.assign({}, publications)
//   newPublications[tristUrl]  = revision
//   state = Object.assign({}, state, {publications:newPublications})
//   return state
// }

const reducer = (state = defaultState, action) => {
  // console.log(action)
  state = extend({}, state)
  state.timeline        = TIMELINE     (state, action)
  state.appvars         = APPVARS      (state, action)
//   state.mappvars        = MOBILEVARS   (state, action)
  state.vtrists         = VTRISTS      (state, action)
  state.editor          = EDITOR       (state, action)
//   state.mobileEditState = MOBILEEDITOR (state, action)
//   state.snackbar        = SNACKBAR     (state.snackbar, action)
  state.activePage      = ACTIVEPAGE   (state, action)
//   state.async           = ASYNC        (state, action)
//   state.sharing         = SHARING      (state, action)
//   state.tristrefs       = TRISTREFS    (state, action)
//   state                 = dlgPublishReducer  (state, action)

  state.DLG_FIND        = dlgFindReducer     (state.DLG_FIND,    action)
//   state.DLG_OPENFILE    = dlgOpenfileReducer (state.DLG_OPENFILE, action)
//   state.DLG_FILENAME    = dlgNewfileReducer  (state.DLG_FILENAME, action)
//   state.DLG_USERNAME    = dlgUsernameReducer (state.DLG_USERNAME, action)
//   state.DLG_SHARE       = dlgShareReducer    (state.DLG_SHARE, action)
//   switch(action.type) {
//     case 'SET_PUBLICATIONS': return setPublications(state, action)
//     case 'UPDATE_PUBLICATION': return updatePublication(state, action)
//   }
//console.log(state)
   return state
}

export default reducer


// // parked
//     //         case RA.LINES_COPIED:     return linesCopied(state, action)
//     //         case RA.SET_GADGETPANE: return setGadgetPane(state, action.payload)
//     //         case RA.HIDEGADGETS:    return hideGadgets(state)
//     //         case RA.SHOWGADGETS:    return showGadgets(state)
//     //         case RA.CHANGE_STATE:   return setState(state, action.payload)
//     //         case RA.SET_ANON:     return ASSIGN(state,[{isAnon:action.payload}])
//     //         case RA.LOGOUT:       return logout(state)
//     //         case RA.SET_TEMPLATES:     return ajaxSetTemplates(state, action)
//     //         case 'APPEND_VTRIST':     return appendVTrist(state, action)

//     // import { Trist, Node } from '../trist'
//     // import { TryUrl } from '../tryurl'
//     // import { Family, GadgetPaneOptions, Perms } from '../constants'
//     // import { Line } from '../line'
//     // import { TristId } from '../tristid'
//     // const placeHolder           :IReducer = state => state
//     // const hideGadgets           :IReducer = state => ASSIGN( state, [ { MOBILE_gadgetsHidden:true }] )
//     // const showGadgets           :IReducer = state => ASSIGN( state, [ { MOBILE_gadgetsHidden:false }] )
//     // const setState              :IReducer = (state, action) => ASSIGN(state, [action.payload])
//     // const ajaxSetTemplates      :IReducer = (state, action) => ASSIGN(state, [{templates:action.templates}])
//     // const escaped = escapeRegExp(searchTerm)
//     // // ================================ AJAX ====================================== //
//     // const logout : IReducer = state => {
//     //     let newState:Object[] = [];
//     //     if(!state.leftNavHidden) newState.push({leftNavHidden:true})
//     //     window.location.href = "/logout";
//     //     return ASSIGN(state, newState)
//     // }

//     //
//     // const replaceTrist:IReducer = (state, action) => {
//     //     const {vtrist, vtrist:{tristId}} = action
//     //     let {vtrists, activePage} = state
//     //     const index = Utils.indexOfTristId(state, tristId)
//     //     if(index < 0) return state // might have been closed while waiting for the download
//     //     const isActive = tristId === activePage.tristId
//     //     vtrists = clone(vtrists)
//     //     vtrists[index] = vtrist
//     //     return ASSIGN2(state, {vtrists}) 
//     // }
//     // const setGadgetPane:IReducer = (state, action) => {
//     //     let value = <GadgetPaneOptions>action.payload;
//     //     return ASSIGN(state, [{MOBILE_activeGadgetPane:value}])
//     // }
// //

