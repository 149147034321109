// import {map, each} from 'lodash'
import {isUndefined} from 'lodash'
import Moment   from 'moment'
import $ from 'jquery'
import { KeyCodes as KC } from '@trystal/keys'
import { fromHTML, fromJSON, fromText} from './clipform-a'
// import keycode from 'keycode'
// //--------------------
// import {ClickStates,ClickActions,LinkTypes} from './constants'
// import {Trist,Node,NodeStates} from './trist'
// import {KeyCodes, keyCodeOf} from './keys'
// import {Line,ILineFormat} from './line'
// var _ = require('lodash')

// //--------------------

// export class KeySpec {
//   constructor(public inEditMode:boolean, public keyCode:KeyCodes, public partial: KeyCodes = null) {}
// }

// export const noticeEvent = e => console.log(`..keycode is`, keycode(e))
// /**
//  * @when Date
//  */
export const workTheDate = when => {
  const THEN = Moment(when)
  const NOW = Moment()
  const days = NOW.diff(THEN, 'days')
  return days < 4 ? THEN.fromNow(true) : THEN.format(NOW.diff(THEN, 'months') < 1 ? 'MMM D' : 'YYYY-MM-DD')
}
// export function lineSelector(line) {
//   let id = null
//   if (typeof line === 'string') id = line
//   else id = line.id
//   return `#${id} .CNT`
// }
const clumpSiblings = clip => {
  const clumpSize = 25
  const visibles = clip.toArray(true)
  if (!visibles) return
  const len = visibles.length
  const lastIndex = len - 1
  let i0 = 0
  let i1 = i0 + clumpSize - 1
  while (i1 <= lastIndex) {
    const anchor = visibles[i0 + 1]
    const focus = visibles[i1]
    clip.setRange(anchor, focus)
    clip.indent()
    clip.navParent()
    clip.close()
    i0 = i1 + 1
    i1 = i0 + clumpSize - 1
  }
}
const getMimeType = (types) => {
  if (types.contains) {
    if (types.contains('application/x-trystal.tryst+json')) return 'application/x-trystal.tryst+json'
    if (types.contains('text/html')) return 'text/html'
    if (types.contains('text/plain')) return 'text/plain'
    return null
  }
  if (types.indexOf) {
    if (types.indexOf('application/x-trystal.tryst+json') >= 0) return 'application/x-trystal.tryst+json'
    if (types.indexOf('text/html') >= 0) return 'text/html'
    if (types.indexOf('text/plain') >= 0) return 'text/plain'
    else return null
  }
  return null
}

// // export function computeClickState2(state:NodeStates, isFocus:boolean): ClickStates {
// //   if(!isFocus) return ClickStates.Unfocused
// //   switch(state) {
// //     case NodeStates.OPEN: return ClickStates.FocusedAndOpen
// //     case NodeStates.CLOSED: return ClickStates.FocusedAndClosed
// //     case NodeStates.BOTH: return ClickStates.FocusedAndBoth
// //     default: return ClickStates.Focused
// //   }
// // }
// // // export function computeClickAction2(node:Node, isFocus:boolean) : IAction | ViewActions {
// // //   switch(computeClickState2(node.state, isFocus)) {
// // //     case ClickStates.FocusedAndOpen: return ViewActions.CloseFocus
// // //     case ClickStates.FocusedAndClosed: return ViewActions.OpenFocus
// // //     case ClickStates.FocusedAndBoth: return ViewActions.OpenFocus
// // //     case ClickStates.Unfocused: return {action: ViewActions.SetFocusedLine, payload: node}
// // //     case ClickStates.None: return ViewActions.Blur
// // //     default: return null
// // //   }
// // // }

// // export function computeClickState(node:Node, isFocus:boolean): ClickStates {
// //   if(!node) return ClickStates.None
// //   if(!isFocus) return ClickStates.Unfocused
// //   switch(node.state) {
// //     case NodeStates.OPEN: return ClickStates.FocusedAndOpen
// //     case NodeStates.CLOSED: return ClickStates.FocusedAndClosed
// //     case NodeStates.BOTH: return ClickStates.FocusedAndBoth
// //     default: return ClickStates.Focused
// //   }
// // }
// // // export function computeClickAction(node:Node, isFocus:boolean) : IAction | ViewActions {
// // //   switch(computeClickState(node, isFocus)) {
// // //     case ClickStates.FocusedAndOpen: return ViewActions.CloseFocus
// // //     case ClickStates.FocusedAndClosed: return ViewActions.OpenFocus
// // //     case ClickStates.FocusedAndBoth: return ViewActions.OpenFocus
// // //     case ClickStates.Unfocused: return { action: ViewActions.SetFocusedLine, payload: node}
// // //     case ClickStates.None: return ViewActions.Blur
// // //     default: return null
// // //   }
// // // }
export function getTristFromClipboard(evt) {
  const clipFromMime = (mimeType, rawContent) => {
    switch (mimeType) {
      case 'application/x-trystal.tryst+json': return fromJSON(JSON.parse(rawContent))
      case 'text/html': return fromHTML($(rawContent).toArray())
      case 'text/plain': return fromText(rawContent)
      default: console.log(mimeType); return null
    }
  }
  const mimeType = getMimeType(evt.clipboardData.types)
  if (!mimeType) return null
  const rawContent = evt.clipboardData.getData(mimeType)
  const clip = clipFromMime(mimeType, rawContent)
  if (clip) clumpSiblings(clip)
  return clip
}

// const sizeTags = ['s1', 's2', 's3', 's4', 's5']
// const faceTags = ['f0', 'f1', 'f2']
// const colorTags = map('123456789abcdefghijklmnopqrstuvw', c => 'c' + c)
// const bgTags = ['bg0', 'bg1', 'bg2', 'bg3', 'bg4', 'bg5']
// const fgTags = ['fg0', 'fg2', 'fg3', 'fg4', 'fg5']
// const validTags = ['b', ...sizeTags, ...faceTags, ...colorTags, ...bgTags, ...fgTags]
// const BGCODES1 = ['rgb(255, 255, 255)', 'rgb(255, 255, 0)', 'rgb(176, 224, 230)', 'rgb(152, 251, 152)', 'rgb(255, 228, 225)', 'rgb(211, 211, 211)']
// const BGCODES2 = [null, 'yellow', 'powderblue', 'palegreen', 'mistyrose', 'lightgrey']
// const BGCODES3 = [null, '#ffff00', '#b0e0e6', '#98fb98', '#ffe4e1', '#d3d3d3']
// const FGCODES1 = ['#000000', '---', '#0000ff', '#008000', '#ff0000', '#808080']
// const FGCODES2 = ['black', '---', 'blue', 'green', 'red', 'gray']
// const FGCODES3 = ['rgb(0, 0, 0)', '---', 'rgb(0, 0, 255)', 'rgb(0, 128, 0)', 'rgb(255, 0, 0)', 'rgb(128, 128, 128)']
// const SIZES = ['11px', '14px', '16px', '20px', '24px']
function computeOutline(isMoving, isFocus, isEditing) {
  //if(isMoving) return 'red 1px solid'
  if (!isFocus) return null
  if (isEditing) return 'red 1px solid'
  return 'lightgray 1px solid'
}
function computeShadow(isMoving, isFocus, isSelected) {
  if (isMoving) return '0 19px 60px rgba(0, 0, 0, 0.30), 0 15px 20px rgba(0, 0, 0, 0.22)'
  if (isFocus || isSelected) return '0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23)'
  return null
}
const PARTIALKEYS_EDIT = [KC.CTRLH, KC.CTRLA, KC.CTRLS, KC.CTRLF, KC.CTRL1]
const PARTIALKEYS = [KC.H, KC.A, KC.S, KC.F]
export const isPartialKey = (n, editMode = false) => {
  if (editMode) return PARTIALKEYS_EDIT.indexOf(n) >= 0
  return PARTIALKEYS.indexOf(n) >= 0
}
export function buildPaperStyle(isFocus, isSelected, isMoving, isEditing) {
  let outline = computeOutline(isMoving, isFocus, isEditing)
  let shadow = computeShadow(isMoving, isFocus, isSelected)
  if (isFocus || isSelected) return {
    boxShadow: shadow,
    outline: outline,
    transition: 'cubic-bezier(0.23, 1, 0.32, 1)',
    marginRight: 5,
    zIndex: 2,
    backgroundColor: 'white'
  }
  return {
    marginRight: 5,
    zIndex: 0
  }
}
export function placeCaretAtEnd1(el) {
  const body = document.body
  if (typeof body.createTextRange != 'undefined') {
    const textRange = body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}
export function placeCaretAtEnd(el) {
  if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    el.focus()
  }
  else placeCaretAtEnd1(el)
}
export function buildFormatClasses2(format) {
  // bg removed, since it is implemented in a container instead of in the content
  const {fg, family, fontSize, bisu} = format
  let classes = ['CNT']
  if (fg) classes.push(`fg${fg}`)
  if (!isUndefined(family)) classes.push(`f${family}`)
  if (!isUndefined(fontSize)) classes.push(`s${fontSize}`)
  if (/b/.test(bisu)) classes.push('bold')
  if (/i/.test(bisu)) classes.push('italic')
  const isStrikeout = /s/.test(bisu)
  const isUnderline = /u/.test(bisu)
  if (isStrikeout && isUnderline) classes.push('understruck')
  else if (isStrikeout) classes.push('strikeout')
  else if (isUnderline) classes.push('underline')
  return classes.join(' ')
}

// // export function buildFormatClasses(line:Line) {
// //   var classes : string[] = ['CNT']
// //   if(line.bg) classes.push('bg' + line.bg);  //  <== pushed to a container
// //   if(line.fg) classes.push('fg' + line.fg)
// //   if(!isUndefined(line.family)) classes.push('f' + line.family)
// //   if(!isUndefined(line.fontSize)) classes.push('s' + line.fontSize)
// //   if(line.isBold) classes.push('bold')
// //   if(line.isItalic) classes.push('italic')
// //   if(line.isStrikeout && line.isUnderline) classes.push('understruck')
// //   else if(line.isStrikeout) classes.push('strikeout')
// //   else if(line.isUnderline) classes.push('underline')
// //   return classes.join(' ')
// // }

// // export enum Contexts {
// //   AppHome, AppRW, AppRO,
// //   LineRO,
// //   LineRW_LINEMODE, LineRW_EDITMODE
// // }

// // let refreshCursorTimer : NodeJS.Timer

// // export function refreshFocus(el:HTMLElement, msg:string) {
// //   if(refreshCursorTimer) {
// //     clearTimeout(refreshCursorTimer)
// //     refreshCursorTimer = null
// //   }
// //   refreshCursorTimer = <any>setTimeout(() => {
// //     // if(msg)
// //       console.log(msg || 'refreshFocus(no message)')
// //     el.focus()
// //   }, 1000)
// // }

// // let KC = KeyCodes
// // let NOOP = null
// // //let SIMPLE1 = (action:ViewActions, payload:any) => { return {action:action, payload:payload}; }
// // // let SETTABBYINDEX = (n:number) => SIMPLE1(ViewActions.SetTabIndex,n)
// // // // frag
// // // let SETFRAGFAMILY = (n:number) => SIMPLE1(VA.FamilyFragment, n)
// // // let SETFRAGFG = (n:number) => SIMPLE1(VA.FGFragment,n)
// // // let SETFRAGBG = (n:number) => SIMPLE1(VA.BGFragment, n)
// // // let SETFRAGSIZE = (n:number) => SIMPLE1(VA.SizeFragment,n)
// // // let ADDFIELD = (s:string) => SIMPLE1(VA.Special,s)
// // // let SETPARTIALKEY = (kc:KeyCodes) => SIMPLE1(VA.StorePartialKey, kc)

// // const [INTERNAL, TRYSTAL, OTHER] = [1,2,3]

// // // function checkSetTabByIndex(keyspec:KeySpec) : IAction | ViewActions {
// // //   let {keyCode,partial} = keyspec
// // //   if(!partial) switch(keyCode) {
// // //     case KeyCodes.ALT0: return SETTABBYINDEX(-1)
// // //     case KeyCodes.ALT1: return SETTABBYINDEX(0)
// // //     case KeyCodes.ALT2: return SETTABBYINDEX(1)
// // //     case KeyCodes.ALT3: return SETTABBYINDEX(2)
// // //     case KeyCodes.ALT4: return SETTABBYINDEX(3)
// // //     case KeyCodes.ALT5: return SETTABBYINDEX(4)
// // //     case KeyCodes.ALTLEFT: return ViewActions.PrevTab
// // //     case KeyCodes.ALTRIGHT: return ViewActions.NextTab
// // //   }
// // //   return null
// // // }
// // // function checkNavKeys(keyspec:KeySpec) {
// // //   let {keyCode,partial} = keyspec
// // //   if(!partial) switch(keyCode) {
// // //     case KC.UP: return ViewActions.Up
// // //     case KC.DOWN: return ViewActions.Down
// // //     case KC.LEFT: return ViewActions.Left
// // //     case KC.RIGHT: return ViewActions.Right
// // //     case KC.HOME: return ViewActions.Home
// // //     case KC.END: return ViewActions.End
// // //     case KC.SHIFTDOWN: return ViewActions.ShiftDown
// // //     case KC.SHIFTUP: return ViewActions.ShiftUp
// // //     case KC.ESCAPE: return ViewActions.Blur
// // //   }
// // //   return null
// // // }

// // // export function getKeydownAction(context:Contexts, key:KeyCodes|KeySpec) : IAction | ViewActions  {
// // //   let result:IAction |ViewActions
// // //   if(!key) return NOOP
// // //   let ks = (key instanceof KeySpec) ? key : new KeySpec(false,key)
// // //   let {keyCode,inEditMode,partial} = ks
// // //   partial = partial || null

// // //   switch(context) {
// // //     case Contexts.LineRW_EDITMODE:
// // //       switch(partial) {
// // //         case null:
// // //           switch(keyCode) {
// // //             case KC.CTRLA:
// // //             case KC.CTRL1: return SETPARTIALKEY(keyCode)
// // //             case KC.F5: return VA.Suppress
// // //             case KC.CTRLF4: return VA.Suppress
// // //             case KC.CTRLSPACE: return VA.RemoveAllFormatting; // : removeAllFormatting(); break
// // //             case KC.CTRLL: return VA.CreateLink; // : createLink(); break
// // //             case KC.CTRLSHIFTV: return VA.CreateInternalLink; // : createInternalLink(); break
// // //             case KC.CTRLC:
// // //               console.log('caught a ctrl-c key combination')
// // //               return VA.PassThrough
// // //             default: return VA.PassThrough
// // //           }
// // //         case KC.CTRLA:
// // //           switch(keyCode) {
// // //             case KC.ONE: return SETFRAGFAMILY(0)
// // //             case KC.TWO: return SETFRAGFAMILY(1)
// // //             case KC.THREE: return SETFRAGFAMILY(2)
// // //             default: return NOOP
// // //           }
// // //         case KC.CTRL1:
// // //           switch(keyCode) {
// // //             case KC.ONE: return ADDFIELD('TODAY')
// // //             case KC.TWO: return ADDFIELD('NOW')
// // //             default: return NOOP
// // //           }
// // //         default: return NOOP
// // //       }
// // //     case Contexts.LineRW_LINEMODE:
// // //       result = checkSetTabByIndex(ks)
// // //       if(!result) result = checkNavKeys(ks)
// // //       if(result) return result
// // //       switch(partial) {
// // //         case null:
// // //           switch(keyCode) {
// // //             case KC.CTRLESC:
// // //             case KC.F10: return VA.CloseTab
// // //             case KC.F5: return VA.Reload
// // //             case KC.METALEFT:
// // //             case KC.CTRLLEFT: return VA.Undent
// // //             case KC.METARIGHT:
// // //             case KC.CTRLRIGHT: return VA.Indent
// // //             case KC.METAUP:
// // //             case KC.CTRLUP: return VA.MoveUp
// // //             case KC.METADOWN:
// // //             case KC.CTRLDOWN: return VA.MoveDown
// // //             case KC.METASPACE:
// // //             case KC.METAENTER:
// // //             case KC.J: return VA.Join
// // //           }
// // //         case KC.H:
// // //         case KC.F:
// // //         case KC.A: // moved
// // //         case KC.S:
// // //         default: return VA.PassThrough
// // //       }
// // //     case Contexts.LineRO:
// // //       result = checkSetTabByIndex(ks)
// // //       if(!result) result = checkNavKeys(ks)
// // //       if(result) return result
// // //       switch(keyCode) {
// // //         case KC.CTRLESC:
// // //         case KC.F10: return VA.CloseTab
// // //         case KC.SPACE: return VA.Suppress
// // //         case KC.CTRLF4: return VA.Suppress
// // //         default: return NOOP
// // //       }
// // //     case Contexts.AppRO:
// // //       switch(keyCode) {
// // //         case KC.CTRLC:
// // //         case KC.CTRLV:
// // //         case KC.CTRLR: return VA.PassThrough
// // //         case KC.CTRLF: return VA.ShowFindDialog
// // //       }
// // //       break
// // //     case Contexts.AppRW:
// // //       switch(keyCode) {
// // //         case KC.CTRLC:
// // //         case KC.CTRLV:
// // //         case KC.CTRLR: return VA.PassThrough
// // //         case KC.CTRLF: return VA.ShowFindDialog
// // //       }
// // //       break
// // //     case Contexts.AppHome:
// // //       result = checkSetTabByIndex(ks)
// // //       if(result) return result
// // //       switch(keyCode) {
// // //         case KC.CTRLESC:
// // //         case KC.CTRLF: return VA.ShowFindDialog
// // //         case KC.F10: return VA.CloseTab
// // //         case KC.F5: return VA.Reload
// // //         case KC.DOWN: return VA.Down
// // //         case KC.UP: return VA.Up
// // //         case KC.HOME: return VA.Home
// // //         case KC.END: return VA.End
// // //         case KC.CTRLC:
// // //         case KC.CTRLV: return VA.PassThrough
// // //         case KC.ENTER: return VA.AddSibling
// // //         case KC.INSERT: return VA.Insert
// // //       }
// // //       break
// // //   }
// // //   return NOOP
// // // }
