import { connect } from 'react-redux'
import * as actions from '../actions/actions'
import React from 'react'

const S1 = {
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  backgroundColor: 'aliceblue',
  height:'25%',
  borderBottom:'lightgray 10px solid'
} 
const S2 = { maxWidth:'100%',  maxHeight:'100%'}

class Carousel extends React.Component {
  doit(e) {
    e.preventDefault()
    e.stopPropagation()
    const {images, imageIndex, setFocus} = this.props
    if(!images || images.length < 2) return
    let nextIndex = imageIndex + 1
    if(nextIndex === images.length) nextIndex = 0
    setFocus(images[nextIndex].lineId)
  }
  render() {
    const {images, imageIndex} = this.props
    if(!images) return null
    const image = images[imageIndex].image
    return (<div style={S1}>
      <img alt='hola' style={S2} src={image} onTouchTap={this.doit.bind(this)} />
    </div>)
  }  
}

const mapStateToProps = (state, props) => {
  const vtrist = state.vtrists.find(vt => vt.tristUrl === props.tristUrl)
  const {images, ui:{imageIndex}} = vtrist
  if(!images || !images.length) return { image:null }
  return {images, imageIndex:imageIndex || 0}
}

const mapDispatchToProps = (dispatch, props) => {
  const {tristUrl} = props
  const setFocus = lineId => {
    dispatch(actions.setFocusToLine(tristUrl, lineId))
  }
  return {setFocus}
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Carousel)











