import {TristId} from './tristid'

const [EDIT,PUBLISHED] = [1, 2]

export const Views = {
    edit      :EDIT,
    published :PUBLISHED
}
const viewnames = ['edit','published']
export const ViewName = n => viewnames[n-1]

export const buildTurl = (owner, filename, perm) => {
    if(!filename) return null
    let view = 'edit'
    let turl = new TryUrl(`trystal://${owner}/${filename}/${view}`)
    // if(!SELECT.isOpenOrOpening(state, turl)) return null
    turl.ro = perm !== 'rw'
    return turl
}

export class TryUrl {
  constructor(uid, filename, view=2, lineId=null, isRO=true) {
    this._uid = uid
    this._filename = filename
    this._view = view
    this._lineId = lineId
    this._ro = isRO
    const bits = [uid, filename]
    if(view) bits.push(ViewName(view))
    if(lineId) bits.push(`#${lineId}`)
    this._isValid = !!uid && !!filename 
    this._url = `trystal://${bits.join('/')}`
  }
  static UIDFN(tristUrl) {
    const turl = TryUrl.Parse(tristUrl)
    return turl ? {uid:turl.uid,filename:turl.filename} : {uid:null, filename:null}
  }
  static Parse(url) {
    const wip = {
      url,
      lineId : null,
      view   : Views.published,
      isRO   : true
    }
    let checkForLineId = wip => {
      const {url} = wip
      const rgx = /#([a-z0-9]+)$/i
      const matches = rgx.exec(url)
      wip.lineId = matches ? matches[1] :  null
      if(matches) wip.url = url.replace(rgx,'')
    }
    const checkForRender = wip => {
      const {url} = wip 
      const rgx = /\/(published|edit)$/i
      const matches = rgx.exec(url)
      wip.view = matches ? Views[matches[1]] : Views.published
      wip.isRO = wip.view === Views.published
      if(matches) wip.url = url.replace(rgx,'')
    }
    checkForLineId(wip)
    checkForRender(wip)
    
    const re = /^trystal:\/\/([^/]{2,20})\/([^/]{3,50})$/i
    const matches = re.exec(wip.url)
    if(!matches) return null
    const uid = matches[1]
    const filename = matches[2]
    const {view, lineId, isRO} = wip
    return new TryUrl(uid, filename, view, lineId, isRO)
  }
  get tristUrl() { 
    return `trystal://${this.uid}/${this.filename}` 
  }
  get url() { return this._url }
  get uid() { return this._uid }
  get filename() { return this._filename }
  get view() { return this._view }
  get lineId() { return this.lineId }
  get isValid() { return this._isValid }
  get ro() { return this._ro }
  set ro(value) { this._ro = value }

  get tristId() { return new TristId(this.uid, this.filename) }
}
export default TryUrl