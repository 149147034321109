import $ from 'jquery'
import { extend, isEqual } from 'lodash'
import { 
  ReduxActions as RA, 
  VisibleTristActions as VTA 
} from '../constants'


const [CLEAN, DIRTY, TRYSTUP/*, NEW*/] = ['CLEAN','SHALLOW','DEEP', 'NEW']
const DEFAULT_UI = { formatBar:false, arrangeBar:false, imageIndex:null}

const DEFAULTREDUXTRIST = () => ({
  tristUrl      : null,
  trist         : null,  // <=== this should not be in redux, but we don't have time to move it yet
  title         : null,
  vlines        : [],
  isDirty       : false,
  isEditable    : false,
  isEditing     : false,
  anchorId      : null,
  focusId       : null,
  canUndo       : false,
  canRedo       : false,
  copiedLineId  : null,
  image         : null,
  ui            : DEFAULT_UI
})
function buildVlineIndex(vlines) {
  const index = {}
  vlines.forEach(vline => index[vline.id] = vline)
  return index
}
function payloadToHtml({payload=null, isEditing=false}) {
  const showFields = isEditing
  let {rendered:html, imageLinks} =  payload.toHtml(showFields)
  if(isEditing && $(`<div>${html}</div>`).text().trim().length === 0) html = ''
  const imageLink = imageLinks && imageLinks.length ? imageLinks[0] : null 
  return { html, imageLink }
}
function buildVlineFromScratch(seq, node, isFocus, isEditing, isSelected, navigation) {
  const {id, state, level, payload} = node
  const {trystup, format} = payload
  
  // if(isEditing){
  //     const draftJS = payload.toDraftJS()
  // } 
  
  const {html, imageLink} = payloadToHtml({payload, isEditing})
  return { 
    seq, id, state, level, isEditing, isSelected, isFocus, trystup, format, html, imageLink,
    navigation, 
    displace:{isMoving:false, x:0, y:0}
  }
}
function isDirty(vline, node, seq, isFocus, selectedLines, isEditing, navigation) {
  if(!vline) return TRYSTUP
  const line = node.payload
  if(vline.trystup !== line.trystup) return TRYSTUP
  
  if(vline.isEditing !== isEditing) return DIRTY
  if(vline.seq !== seq) return DIRTY
  if(isFocus !== vline.isFocus) return DIRTY
  if(vline.state !== node.state) return DIRTY
  if(vline.level !== node.level) return DIRTY 
  if(vline.imageLink !== line.imgLink) return DIRTY
  if(vline.isFocus !== isFocus) return DIRTY
  const isSelected = !!selectedLines && (selectedLines.length > 0) && selectedLines.indexOf(vline.id) >= 0
  if(vline.isSelected !== isSelected) return DIRTY
  if(!isEqual(vline.navigation, navigation)) return DIRTY
   
  return CLEAN
}
function buildNavigation(node, focus) {
  if(node!== focus) return null
  return {
    up:   focus.PV ? focus.PV.id : null,
    down: focus.NV ? focus.NV.id : null
  }
}
// 2019-11-06 adding visibleOnly parameter as a quick and dirty way to create a fully expanded version for bots
function generateVlines(trist, vtrist, visibleOnly=true) {
  const vlines = vtrist ? vtrist.vlines : []
  const isVTristEditing = vtrist ? vtrist.isEditing : false
  const {focus} = trist
  const nodes = trist.toArray(visibleOnly)
  const vlineIndex = buildVlineIndex(vlines)
  const selectedIds = trist.range().toArray(true).map(node => node.id)
  let seq = -1
  const vlineList = nodes.map(node => {
    seq++
    const id = node.id
    const vline = vlineIndex[id] || null
    const isFocus = node === focus
    const isEditing = isFocus && isVTristEditing
    const isSelected = !!selectedIds && (selectedIds.length > 0) && selectedIds.indexOf(id) >= 0
    const navigation = buildNavigation(node, focus)
    if(vline == null) return buildVlineFromScratch(seq, node, isFocus, isEditing, isSelected, navigation)
    const dirtyness = isDirty(vline, node, seq, isFocus, selectedIds, isEditing, navigation)
    if(dirtyness === CLEAN) return vline
    return buildVlineFromScratch(seq, node, isFocus, isEditing, isSelected, navigation)
  })
  return vlineList
}

function setImageLink(vtrist) {
  const {vlines, images, focusId} = vtrist
  if(!images || !focusId) return
  const vline = vlines.find(vl => vl.isFocus)
  const imageLink = vline.imageLink
  if(!imageLink) return
  vtrist.ui.imageIndex = images.findIndex(img => img.image === imageLink)
}

function extractImages(vtrist) {
  const newImages = vtrist
    .vlines
    .filter(item => !!item.imageLink)
    .map(vline => ({ lineId: vline.id, image: vline.imageLink}))
  return isEqual(newImages, vtrist.images) ? vtrist.images : newImages
}

function rebuildVisibleTrist(oldVTrist = DEFAULTREDUXTRIST(), trist, edits) {
  if(!trist) trist = oldVTrist.trist
  const {anchor, focus, canUndo, canRedo, isDirty} = trist
  const anchorId = anchor ? anchor.payload.id : null
  const focusId = focus ? focus.payload.id : null
  let rebuilt = extend({}, oldVTrist, {canUndo, canRedo, isDirty, anchorId, focusId})
  if(edits) extend(rebuilt, edits)
  rebuilt.vlines = generateVlines(trist, rebuilt)
  setImageLink(rebuilt)
  rebuilt.images = extractImages(rebuilt)
  return rebuilt
}
function _buildVisibleTrist(trist, tristUrl, isEditable, url, visibleOnly=true)  {
  const {anchor, focus, canUndo, canRedo, isDirty} = trist
  const anchorId = anchor ? anchor.payload.id : null
  const focusId = focus ? focus.payload.id : null
  const vlines = generateVlines(trist,null, visibleOnly)

  let vtrist = DEFAULTREDUXTRIST()
  extend(vtrist, {trist, tristUrl, isEditable, url})
  extend(vtrist, {canUndo, canRedo, isDirty, anchorId, focusId, vlines} )
  setImageLink(vtrist)
  vtrist.images = extractImages(vtrist)
  return vtrist
}
export const buildVisibleTrist = ({trist, tristUrl, isEditable, url, visibleOnly=true}) => _buildVisibleTrist(trist, tristUrl, isEditable, url, visibleOnly)

// export function buildVisibleTrist(action) {
//     const {trist, tristUrl, isEditable} = action
//     return _buildVisibleTrist(trist, tristUrl, isEditable)
// }
function _setFocusToNode(vtrist, focus) {
  if(vtrist.isEditing) return vtrist
  vtrist.trist.setRange(focus)
  return rebuildVisibleTrist(vtrist)
}
function _setFocusToId(vtrist, focusId) {
  return _setFocusToNode(vtrist, vtrist.trist.getById(focusId))
}
function lineDown(vtrist) {
  const {trist,trist:{focus}} = vtrist
  if(!focus) return _setFocusToNode(trist.first)
  if(!focus.NV) return vtrist
  return _setFocusToNode(vtrist, focus.NV)
}
function lineUp(vtrist) {
  const {trist,trist:{focus}} = vtrist
  if(!focus) return _setFocusToNode(vtrist, trist.lastVisible)
  if(!focus.PV) return vtrist
  return _setFocusToNode(vtrist, focus.PV)
}
function lineOpen(vtrist) {
  if(!vtrist.focusId) return vtrist
  const {trist} = vtrist
  trist.open(trist.focus)
  return rebuildVisibleTrist(vtrist)
}
function lineClose(vtrist) {
  if(!vtrist.focusId) return vtrist
  const {trist} = vtrist
  trist.close(trist.focus)
  return rebuildVisibleTrist(vtrist)
}
function lineRight(vtrist) {
  const {trist} = vtrist
  const {focus} = trist
  if(trist.canExpand) return lineOpen(vtrist)   // and this....
  if(focus.NV) return _setFocusToNode(vtrist, focus.NV)
  return vtrist
}
function lineLeft(vtrist) {
  const {trist} =vtrist
  const {focus} = trist
  if(trist.canCollapse) return lineClose(vtrist)  // ============= gotta do something about this
  if(focus.vparent) return _setFocusToNode(vtrist, focus.vparent)
  if(focus.PV) return _setFocusToNode(vtrist, focus.PV)
  return vtrist
}
function moveDown(vtrist) {
  let {trist} = vtrist
  if(!trist.canMoveDown) return vtrist
  trist.moveDown()
  return rebuildVisibleTrist(vtrist)
}
function moveUp(vtrist) {
  let {trist} = vtrist
  if(!trist.canMoveUp) return vtrist
  trist.moveUp()
  return rebuildVisibleTrist(vtrist)
}
function undo(vtrist) {
  const {trist} = vtrist
  if(!trist.canUndo) return vtrist
  trist.undo()
  return rebuildVisibleTrist(vtrist)
}
function redo(vtrist) {
  const {trist} = vtrist
  if(!trist.canRedo) return vtrist
  trist.redo()
  return rebuildVisibleTrist(vtrist)
}
function extendDown(vtrist) {
  const {trist} = vtrist
  if(!vtrist.isEditable || !trist.canExtendDown) return vtrist
  trist.setRange(trist.anchor, trist.focus.NV)
  return rebuildVisibleTrist(vtrist)
}
function extendUp(vtrist) {
  const {trist} = vtrist
  if(!vtrist.isEditable || !trist.canExtendUp) return vtrist
  trist.setRange(trist.anchor, trist.focus.PV)
  return rebuildVisibleTrist(vtrist)
}
function blur(vtrist) {
  if(!vtrist.focusId) return vtrist
  vtrist.trist.setRange(null)
  return rebuildVisibleTrist(vtrist)    
}
function find(state, vtrist, action) {
  const {searchRegex} = state.appvars
  if(!searchRegex) return vtrist
  const {searchUp, fromStart} = action
  const {trist} = vtrist
  const match = trist.find(node => searchRegex.test(node.payload.trystup), fromStart, searchUp)
  if(!match) {
    alert('not found')
    return vtrist
  }
  trist.setRange(match)
  return rebuildVisibleTrist(vtrist)
}

function UI(ui, action) {
  switch(action.vtristActionType) {
  case VTA.SET_IMAGEINDEX:  return extend({},ui,{imageIndex:action.imageIndex})
  default: return ui
  }
}

function VTRIST(state, vtrist=DEFAULTREDUXTRIST(), action) {
  if(action.type  !== RA.VTRIST_ACTION) return vtrist
  if(action.tristUrl !== vtrist.tristUrl) return vtrist
  const ui = UI(vtrist.ui, action)
  if(ui !== vtrist.ui) return extend({},vtrist,{ui})
  if(vtrist.isEditing) {
    switch(action.vtristActionType) {
      case VTA.EDIT_END:         return rebuildVisibleTrist(vtrist, null, {isEditing:false})
      default: return vtrist
    }
  }
  switch(action.vtristActionType) {
    case VTA.BLUR:             return blur(vtrist)
    case VTA.EXTEND_UP:        return extendUp(vtrist)
    case VTA.EXTEND_DOWN:      return extendDown(vtrist)
    case VTA.SET_FOCUS:        return _setFocusToId(vtrist, action.focusId)
    case VTA.HOME:             return _setFocusToNode(vtrist, vtrist.trist.first)
    case VTA.END:              return _setFocusToNode(vtrist, vtrist.trist.lastVisible)
    case VTA.UP:               return lineUp(vtrist, action)
    case VTA.DOWN:             return lineDown(vtrist, action)
    case VTA.RIGHT:            return lineRight(vtrist)
    case VTA.LEFT:             return lineLeft(vtrist)
    case VTA.OPEN:             return lineOpen(vtrist)
    case VTA.CLOSE:            return lineClose(vtrist)
    case VTA.MOVE_DOWN:        return moveDown(vtrist)
    case VTA.MOVE_UP:          return moveUp(vtrist)
    case VTA.UNDO:             return undo(vtrist)
    case VTA.REDO:             return redo(vtrist)
    case VTA.REFRESH_VTRIST:   return rebuildVisibleTrist(vtrist)
    case VTA.REPLACE_TRIST:    return _buildVisibleTrist(action.trist, vtrist.tristUrl, vtrist.isEditable)
    case VTA.FIND:             return find(state, vtrist, action)
    default: return vtrist
  }
}

export default VTRIST