/*eslint react/no-danger:0 */
import _ from 'lodash'
import { extend } from 'lodash'
import React from 'react'
import { /*Container, Icon, */Label/*, Menu*/ } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes     from 'prop-types'

import { BGColors        } from '../constants'
import { buildPaperStyle } from '../util/util'
import VisibleContent from './visible-content'
import VisibleEditor  from './visible-editor-draft'

import TryUrl    from '../util/tryurl'
import { keyCodeOf, KeyCodes as KC } from '@trystal/keys'
import { NodeStates }                from '@trystal/tryst/constants'

import { isPartialKey }     from '../util/util'

import {ajaxLoadTrist, ajaxLoadLinelo} from '../actions/actions-thunk'

import { 
    dlgFindShow,
    find, 
    blur,
    setPartialKey,
    setFocusToLine, 
    lineOpen, lineClose,
    copySelection
} from '../actions/actions'

import vlineSelectorFactory from '../selectors/visible-line'

const LEVELWIDTH = 15

const getBadgeLevel = (level, levelWidth, displace) => {
  if (!displace) return level
  if (!displace.isMoving) return level
  level = level + Math.round(displace.x / levelWidth)
  return level < 0 ? 0 : level
}
const getBadgeProps = (level, levelWidth, displace, isTrystFocus) => ({
  text: getBadgeLevel(level, levelWidth, displace),
  visible: isTrystFocus && displace.isMoving
})

function getContentContainerProps(format, isSelected, isEditing, isTrystFocus, displace) {
  const className = 'CONTENTCONTAINER'
  const isMoving = displace && displace.isMoving
  const style = buildPaperStyle(isTrystFocus, isSelected, isMoving, isEditing)
  if (!_.isUndefined(format.bg)) style.backgroundColor = BGColors[format.bg]
  style.position = 'relative'
  style.width = '100%'
  if (displace && displace.isMoving) {
    const {x, y} = displace
    if (x || y) style.transform = `translate(${x}px,${y}px)`
    style.backgroundColor = 'white'
  }
  return { className, style }
}
const getSpacerProps = level => ({
  className: 'SPC',
  style: { width: level * LEVELWIDTH }
})
function getContainerProps(id, format, nodeState, isSelected, isFocus, onClick, onPaste/*, onCopy*/) {
  let {fontSize} = format
  if (_.isUndefined(fontSize)) fontSize = 2
  const result = {
    id, onClick, onPaste,
    className: `LIN s${fontSize}`,
    // 'data-selected': isSelected ? 1 : 0,
    'data-state': nodeState
  }
  if(isSelected) result['data-selected'] = 1
  if(isFocus) result['data-focus'] = 1
  return result
}

const BadgeLabel = ({text, visible}) => visible ? <Label floating circular size='tiny' color='olive' style={{top:'-1.3em',left:5}}>{text}</Label> : null

class VisibleLine extends React.Component {
  constructor(props) {
    super(props)
    this.state = { key0: null }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.vline !== nextProps.vline) return true
    if (nextProps.vline.isFocus) {
      if (nextProps.partialKey !== this.props.partialKey) return true
      if (nextProps.editorState !== this.props.editorState) return true
    }
    return false
  }
  handleKeyDown(e) {
    const {vline, partialKey, onKeyDown /*, selectedLinesCount*/} = this.props
    const keyCode = keyCodeOf(e)
    switch (keyCode) {
      // case KC.DEL: return this.handlers.onDelete(e, selectedLinesCount)
    default: return onKeyDown(e, vline, partialKey)
    }
  }
  render() {
    const {tristUrl, vline, onClick, onCopy, onPaste, onEndEdit, editorState, dispatch} = this.props
    const {
      id, format, state, isSelected,
      level, displace, isFocus,
      isEditing
    } = vline

    const handleClick = e => {
      if (isEditing) return
      onClick(e, vline)
    }

    const containerProps = getContainerProps(id, format, state, isSelected, isFocus, handleClick, onPaste, onCopy)
    const badgeProps = getBadgeProps(level, LEVELWIDTH, displace, isFocus) // {text, visible}
    const contentContainerProps = getContentContainerProps(format, isSelected, isEditing, isFocus, displace)
    const spacerProps = getSpacerProps(level)

    const content = isEditing
      ? <VisibleEditor {...{ tristUrl, vline, dispatch, editorState, onEndEdit }} />
      : <VisibleContent vline={vline} />

    return (<div {...containerProps} onKeyDown={this.handleKeyDown.bind(this) }  onCopy={onCopy}>
      <div className='MGN_PARENT'><div className='MGN' /></div>
      <div {...spacerProps} />
      <div {...contentContainerProps}>
        <BadgeLabel {...badgeProps} />
        <div>{content}</div>
      </div>
    </div>)
  }
}
VisibleLine.contextTypes = {
  store: PropTypes.object
}

const [INTERNAL, TRYSTAL, OTHER] = [1,2,3]

// THIS IS DESK!!!

function makeMapStateToProps() {
    const vlineSelector = vlineSelectorFactory()
    const mapStateToProps = (state, props) => {
        const {vtrists, editor:{editorState}} = state
        const {tristUrl, lineId} = props
        const vtrist = vtrists.find(vt => vt.tristUrl ===  tristUrl)
        const selectedLinesCount = vtrist.trist.range().count
        const {partialKey} = state.appvars
        const vline = vlineSelector(state, {tristUrl, lineId})
        return { vline, partialKey, selectedLinesCount, editorState }
    }
    return mapStateToProps
}

// once again... how can we take some action that depends upon state, but only in response to an event?
// pass the needed aspect of state into the control, and then it either uses it directly, or passes it back

const onKeyCombo = function(dispatch, partialKey, secondKey, tristUrl) {
    dispatch(setPartialKey(null))
}
function handleIt(e, fn, ...rest) {
    e.preventDefault()
    e.stopPropagation()
    fn(...rest)
}

const SYSTEMKEYS = [KC.F11,KC.F12,KC.CTRLV,KC.CTRLC]

const onLineKeyDown = (dispatch, e, tristUrl, vline, partialKey) => {
    if(vline.isEditing)       return 
    const keyCode = keyCodeOf(e)
    if(partialKey)            return handleIt(e, onKeyCombo, dispatch, partialKey, keyCode, tristUrl)
    if(isPartialKey(keyCode)) return handleIt(e, dispatch, setPartialKey(keyCode))

    if(SYSTEMKEYS.indexOf(keyCode)>= 0) return e.stopPropagation()

    switch(keyCode) {
    case KC.CTRLF      : return handleIt(e, dispatch, dlgFindShow(tristUrl))
    case KC.F3         : return handleIt(e, dispatch, find(tristUrl, false, false))
    case KC.SHIFTF3    : return handleIt(e, dispatch, find(tristUrl, true, false))
    case KC.ESCAPE     : return handleIt(e, dispatch, blur(tristUrl))
    default: break
    }
}
const pushClipToClipboard = (clipdata, text, html, json) => {
    try {
        clipdata.setData('text/plain', text)
        clipdata.setData('text/html', html)
        clipdata.setData('application/x-trystal.tryst+json', json) // old school json for backward compatibility
    }
    catch (err) {
        clipdata.setData('Text', text)
    }
}
const onCopy = (dispatch, e, tristUrl, pushClipToClipboard) => {
    e.preventDefault()
    e.stopPropagation()
    const clipdata = e.clipboardData
    dispatch(copySelection(tristUrl, (text, html, json) => pushClipToClipboard(clipdata, text, html, json)))
}
const getLinkType = href => {
    if(/#[a-zA-Z0-9]+$/.test(href)) return INTERNAL
    if(/^trystal:/i.test(href)) return TRYSTAL
    if(/\.try$/i.test(href)) return 'LINELO'
    return OTHER
}
const handleLinkClick = (dispatch, e, tristUrl) => {
  e.stopPropagation()
  const href = e.target.getAttribute('href')
  switch(getLinkType(href)) {
  case OTHER:    return        
  case TRYSTAL:  dispatch(ajaxLoadTrist(TryUrl.Parse(href))); break
  case INTERNAL: dispatch(setFocusToLine(tristUrl, href.substring(1))); break
  case 'LINELO': dispatch(ajaxLoadLinelo(href)); break
  default: break;
  }
  e.preventDefault()
}
const onClick = (dispatch, e, tristUrl, vline) => {
    if(e.target.nodeName === 'A' && vline.isFocus) return handleLinkClick(dispatch, e, tristUrl)
    
    e.preventDefault()
    e.stopPropagation()

    const {id, state} = vline
    if(!vline.isFocus) return dispatch(setFocusToLine(tristUrl, id))
    switch(state) {
    case NodeStates.BOTH: dispatch(lineClose(tristUrl)); return 
    case NodeStates.OPEN: dispatch(lineClose(tristUrl)); return  
    case NodeStates.CLOSED: dispatch(lineOpen(tristUrl)); return 
    default: break;
    }
}
function mapDispatchToProps(dispatch, props) {
    const {tristUrl} = props
    return { 
        onCopy    : e          => onCopy (dispatch, e, tristUrl, pushClipToClipboard), 
        onClick   : (e, vline) => onClick(dispatch, e, tristUrl, vline), 
        onKeyDown : (e, vline, partialKey) => onLineKeyDown(dispatch, e, tristUrl, vline, partialKey),
        dispatch
    }
}

function myMergeProps(stateProps, dispatchProps, ownProps) {
    return extend({}, ownProps, stateProps, dispatchProps)
}

const VisibleLineContainer = connect(
    makeMapStateToProps,
    mapDispatchToProps,
    myMergeProps
)(VisibleLine)
export default VisibleLineContainer

