import _ from 'lodash'
import Line, {faces, sizes, bgs, fgs}       from './line'
import {fromJSON as fromJSON2014A} from './REV2014A'
import {fromJSON as fromJSON2015} from './REV2015'

export const Formats = { UNKNOWN : 0, FMT2014 : 20140, FMT2014A : 20141, FMT2015 : 20150 }

export function detectVersion(serverTrist)  {
    if (!serverTrist) return Formats.FMT2015 // why not
    if (serverTrist.version) {
        switch (serverTrist.version) {
        case 20140: return Formats.FMT2014
        case 20141: return Formats.FMT2014A
        case 20150: return Formats.FMT2015
        default: return Formats.UNKNOWN
        }
    }
    if (serverTrist.Lines) {
        const first = _.first(serverTrist.Lines)
        if (!first) return Formats.FMT2014A
        if (first.Lines) return Formats.FMT2014
        return Formats.FMT2014A
    }
    return Formats.UNKNOWN
}
export function toJSON(trist) {
    const parentIndex = {}
    const ja = _.map(trist.toArray(), (N, i) => {
        const payload = N.payload
        const jo = payload.Pack()
        const level = N.level
        if(level) jo.L = N.level
        const parent = N.parent
        if (parent) jo.P = parentIndex[parent.payload.id]
        parentIndex[payload.id] = i
        return jo
    })
    const jo = {}
    jo.Title = ''
    jo.Lines = ja
    return jo
}
export function toHtml(trist=null, options={}) {
    if (trist.isEmpty) return ''
    const {showFields, useStylesheets} = options
    const bodyLines = _.map(trist.toArray(), NODE => {
        const {payload, level} = NODE
        const px = level * 35
        let { rendered:HTML/*, imageLinks */} = payload.toHtml(showFields, useStylesheets)
        if (payload.isBold) HTML = `<strong>${HTML}</strong>`
        if (payload.isItalic) HTML = `<em>${HTML}</em>`
        if (payload.isUnderline) HTML = `<ins>${HTML}</ins>`
        if (payload.isStrikeout) HTML = `<del>${HTML}</del>`
        const styles = []
        styles.push('margin:3px 0px')
        if (px > 0) styles.push(`margin-left:${px}px`)
        if (payload.family && faces[payload.family]) styles.push(`font-family:${faces[payload.family]}`)
        if (payload.fontSize && sizes[payload.fontSize]) styles.push(`font-size:${sizes[payload.fontSize]}px`)
        if (payload.bg && bgs[payload.bg]) styles.push(`background-color:${bgs[payload.bg]}`)
        if (payload.fg && fgs[payload.fg]) styles.push(`color:${fgs[payload.fg]}`)
        return `<p style='${styles.join(';')}'>${HTML}</p>`
    })
    return `<html><body>${bodyLines.join('')}</body></html>`
}

export function EOL() {
    if(window && window.navigator && window.navigator.platform.toLowerCase() === 'win32') return '\r\n'
    return '\n'
}

export function toText(trist=null, options={}) {
    if (trist.isEmpty) return ''
    const {showFields} = options
    const lines = '#BODYLINES#'
    const bodyLines = _.map(trist.toArray(), NODE => {
        const {payload, level} = NODE
        const indent = new Array(level + 1).join('    ')
        const {rendered /*, imageLinks*/} = payload.toText(showFields) 
        return indent + rendered
    }).join(EOL())
    return lines.replace('#BODYLINES#', bodyLines)
}

  // pretty easy to get a 40% reduction in file size by cleaning up the REV2015 format a bit.

export function randomLine(id, index) {
    const content = randomContent(index)
    const NL = new Line(id, content)
    NL.trystup = NL.id + ': ' + content
    if (_.random(1, 6) === 2) NL.isLink = true
    if (_.random(1, 6) === 2) NL.isBold = true
    if (_.random(1, 6) === 2) NL.isItalic = true
    if (_.random(1, 6) === 2) NL.isUnderline = true
    if (_.random(1, 6) === 2) NL.isStrikeout = true
    if (_.random(1, 4) === 2) { NL.bg = _.random(0, 5) } 
    if (_.random(1, 4) === 2) { NL.fg = _.random(0, 5); if (NL.fg === 1) NL.fg = 0 }
    return NL
}
export function randomContent(index) {
    const a = [
        'Once upon a    <i>midnight dreary</i>, while I pondered, weak and weary',
        ': Suddenly there came a tapping, as of someone gently rapping, rappping at my chamber door; tis some visitor I muttered, wrapping at my chamber door',
        ': Tis some visitor I muttered, rapping at my chamber door'
    ]
    if (index < 3) return a[index]
    const randomContent = ['Once upon a midnight dreary', 'while i pondered weak and weary', 'over many a quaint and curious volume', 'of forgotten lore',
        'Ah, distinctly I remember it was in the bleak December', 'And each separate dying ember wrought its ghost upon the floor']
    return randomContent[_.random(0, randomContent.length - 1)]
}
export function lineloLine(rawline) {
    const id = rawline.Id
    const version = _.last(rawline.Content)
    const line = new Line(id, version.Content)
    //--------- formatting --------//
    const attrs = { B: 'isBold', I: 'isItalic', U: 'isUnderline', S: 'isStrikeout' }
    if (rawline.Format) {
        const fbits = rawline.Format.split('-')
        _.each(fbits[0], function (flag) { line[attrs[flag]] = true })
        const Q = ['0', '1', '2', '4', '8', '16']
        line.fg = Q.indexOf(fbits[1])
        line.bg = Q.indexOf(fbits[2])
        line.family = parseInt(fbits[3])
        line.fontSize = parseInt(fbits[4])
        if (fbits[5]) line.marginBottom = parseFloat(fbits[5])
    }
    if (rawline.Link) {
        line.link = rawline.Link
        line.isLink = true
    }
    return line
}

export function tristFromJSON(json) {
  switch (detectVersion(json)) {
    case Formats.FMT2014A: return fromJSON2014A(json)
    case Formats.FMT2015: return fromJSON2015(json)
    default: return null
  }
}
