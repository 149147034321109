// export const Focusables {
//   DUMMY, SearchInput, Trist
// }

// export const TabHeaders { Format,  Move }

export const FGS = [null,null,'blue','green','red','grey']
export const BGS = [null,'yellow','powderblue','palegreen','mistyrose','lightgrey']
export const FACES = ['serif','sans-serif','monospace']

export const GadgetPaneOptions = {
  Format:'format',
  Arrange:'arrange'
}
export const Perms = { None:0, rw:1, ro:2 }
// export const LinkTypes { Unknown, Internal, Trystal, Other }
// export const ViewModes { Line, Edit, EditFields }
// export const ClickStates { None, Unfocused, FocusedAndOpen, FocusedAndClosed, FocusedAndBoth, Focused, NotSelected }
// export const ClickActions { None, CloseFocus, OpenFocus, FocusOne, UnsetFocus }

export const FGColors = ['#000000', null, '#0000ff','#008000','#ff0000','#808080']
export const BGColors = ['#ffffff', '#ffff00', '#b0e0e6', 'palegreen', '#ffe4e1', '#d3d3d3']

export const EditorActions = {
  DO_SOMETHING: 100,
  TOGGLE_STYLE: 101,
  SET_STYLE:    102,
  ADD_LINK:     103,
  UNDO: 104,
  REDO: 105,
  ADD_FIELD: 106
}

export const TestImage = 'https://c.s-microsoft.com/en-ca/CMSImages/Xbox_QuantumBreak_0315_368x207_EN_US.jpg?version=67fc6436-65fa-e9a4-a399-506dbb8147bb'

export const VisibleTristActions = {
  UP: 'UP', DOWN: 2, LEFT: 3, RIGHT:'RIGHT', HOME: 5, END: 6,  
  EDIT:50, EDIT_END:51,    
  PASTE_TRIST: 60, FIND:61, 
  CLEAR_FORMATTING: 70,
  REFRESH_VTRIST:80, REPLACE_TRIST: 81, UNTOUCH     : 82,
    
  SHOW_FORMATBAR:  90, HIDE_FORMATBAR:  91, 
  SHOW_ARRANGEBAR: 92, HIDE_ARRANGEBAR: 93, SET_IMAGEINDEX:94,    
    
  SET_CONTENT :  100, SET_FORMAT :  101, SET_FOCUS  :  'SET_FOCUS',
  SET_IMAGE   :  110, 
  OPEN        :  201, CLOSE      :  202, 
  BLUR        :  504, 
  UNDO        :  'UNDO', REDO       :  'REDO',
  MOVE_RIGHT  :  701, MOVE_LEFT  :  702, MOVE_UP: 703, MOVE_DOWN: 704,
  EXTEND_DOWN :  901, EXTEND_UP  :  902,
  JOIN        : 1001, SPLIT      : 1002,
  ADDLINE     : 1101, INSERTLINE : 1103, DELETE_SELECTION : 1104
}

export const ReduxActions = {
  VTRIST_ACTION   : 'VTRIST_ACTION', EDITOR_ACTION: 2001, SET_EDIT_STATE:2002,
  COPY_SELECTION    : 2100,   
    
  SHOW_SNACK:2200, HIDE_SNACK:2201,
  SET_MOBILEEDIT_UPDATE:2202, 
    
  TRISTACTION:'TRISTACTION',  
  SET_MODE:21,
  SET_FINDEXP:42,
  CLOSE_VTRIST:'CLOSE_VTRIST', 
    
  FINDBAR_SHOW:61, FINDBAR_HIDE:62, LEFTNAV_SHOW:63, LEFTNAV_HIDE:64, FILEPICKER_SHOW:65, 

  SET_INITIALSTATE:'SET_INITIALSTATE', 
  SET_TIMELINE:'SET_TIMELINE',
  CHANGE_STATE:73, 
  SET_PARTIALKEY:74, SET_USERNAME:75, SET_ANON:76, 
  SPECIAL:'SPECIAL', 
    
  LOGOUT:81, 
  LINES_COPIED:91,  
  HIDEGADGETS:101, SHOWGADGETS:102, SET_GADGETPANE:103,

  SERVER_UPDATEUSERNAME:111,

  SET_SUBSCRIPTIONS:121, SET_FILESLIST:122, SET_TEMPLATES:123, ACTIVATE_VTRIST:'ACTIVATE_VTRIST', 

  DLG_PUBLISH_SHOW:201,  DLG_PUBLISH_UPDATE:202,  DLG_PUBLISH_OK:203,    DLG_PUBLISH_CANCEL:204,
  DLG_OPENFILE_SHOW:211, DLG_OPENFILE_UPDATE:212, DLG_OPENFILE_OK:213,   DLG_OPENFILE_CANCEL:214, DLG_OPENFILE_SETVALUE:215,
  DLG_SHARE_SHOW:221,    DLG_SHARE_UPDATE:222,    DLG_SHARE_OK:223,      DLG_SHARE_CANCEL:224,
  DLG_FIND_SHOW:231,     DLG_FIND_UPDATE:232,     DLG_FIND_CANCEL:234, 
  DLG_USERNAME_SHOW:241, DLG_USERNAME_UPDATE:242, DLG_USERNAME_OK:243,   DLG_USERNAME_CANCEL:244, 
  DLG_FILENAME_SHOW:251, DLG_FILENAME_UPDATE:252, DLG_FILENAME_OK:253,   DLG_FILENAME_CANCEL:254,

  UNKNOWN:301
}
