import React  from 'react'
import {Link} from 'react-router-dom'
import {Button, Container, Grid, Header, Icon, List, Menu, Responsive, Segment, Visibility} from 'semantic-ui-react'

const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN

const LegalByLine = () => null //  <div>LegalByLine rocks it!</div>
const Trystalite = () => null // <div>this is the trystalite help</div>
const PickOne = () => {
  switch(REACT_APP_DOMAIN) {
    case 'legalbyline': return <LegalByLine />
    default: return <Trystalite />
  }
}

const LBL = {
  H1:'Legal By Line!',
  H2:'Easily read legal documents',
  favicon:'faviconlgl.ico',
  getStarted:'/_/terry/us.billofrights'
}
const TRY = {
  H1:'Trystalite!',
  H2:'Easily read long, complex documents',
  favicon:'favicon.ico',
  getStarted:'/_/XqANxdiE/lookingglass'
}
const Active = REACT_APP_DOMAIN === 'legalbyline' ? LBL : TRY
document.title = Active.H1
document.querySelector('meta[name="description"]').setAttribute("content", Active.H2);

const favicon = document.getElementById('favicon')
favicon.href = Active.favicon

const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

const LinkButton = () =>  <Button primary size='huge' as={Link} to={Active.getStarted}>Get Started...<Icon name='right arrow' /></Button>

const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content={Active.H1}
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    />
    <Header
      as='h2'
      content={Active.H2}
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <LinkButton />
  </Container>
)

class DesktopContainer extends React.Component {
  state={}
  hideFixedMenu = () => this.setState({fixed:false})
  showFixedMenu = () => this.setState({fixed:true})
  render() {
    const {children} = this.props
    const {fixed} = this.state
    return <Responsive width={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
        <Segment inverted textAlign='center' style={{minHeight:700, padding: '1em 0em'}}>
          <Menu fixed={fixed?'top':null} inverted={!fixed} pointing={!fixed} secondary={!fixed} size='large'>
            <Container>
              <Menu.Item as='a' active>Home</Menu.Item>
              {/* <Menu.Item position='right'><Button as='a' inverted={!fixed}>Log in</Button></Menu.Item> */}
            </Container>
          </Menu>
          <HomepageHeading />
        </Segment>
      </Visibility>
      {children}
      <PickOne />
    </Responsive>
  }
}


class MobileContainer extends React.Component {
  render() {
    return null;
  }
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

const HomepageLayout = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{fontSize:'2.em'}}>
              Awesome way to read documents
            </Header>
            <p style={{ fontSize:'1.33em'}}>
              We can give you superpowers to read documents in ways you never thought possible.
              Let us delight you and give you new powers to work with information...one line at a time.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='About' />
            <List link inverted>
            <List.Item as='a'>Sitemap</List.Item>
            <List.Item as='a'>Contact Us</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header inverted as='h4' content='Services' />
            <List link inverted>
              <List.Item as='a'>Banana Pre-Order</List.Item>
              <List.Item as='a'>DNA FAQ</List.Item>
              <List.Item as='a'>How to access</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as='h4' inverted>
              Legal
            </Header>
            <p><a href='/_/terry/legalbyline.privacy'>Privacy Policy</a></p>
            <p>Terms of Service</p>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export  default HomepageLayout