// import { KeyCodes } from '../keys'
// import { Trist, Node, NodeStates } from '../trist';
// import { ReduxActions, TristActions, Family, GadgetPaneOptions, Perms } from '../constants'

export const PageTypes = { 
  TIMELINE:1,
  VTRIST:2,
  FILEPICKER:3
}

