import React from 'react'
import { connect } from 'react-redux'
import { Popup, Button, Icon } from 'semantic-ui-react'
import { redo } from '../../../../actions/actions'

// const Component = ({disabled, onClick}) => <Button {...{disabled,onClick}} icon><Icon name='undo' flipped='horizontally' /></Button>

const Component = ({disabled, onClick}) => <Popup content='Redo last action' position='bottom right' trigger={<Button {...{disabled,onClick}} icon><Icon name='undo' flipped='horizontally' /></Button>} />


const mapStateToProps = ({vtrists}, {tristUrl}) => ({ disabled: !(vtrists.find(vt => vt.tristUrl === tristUrl)).canRedo })
const mapDispatchToProps = (dispatch, {tristUrl}) => ({ onClick:() => dispatch(redo(tristUrl))})

export default connect(mapStateToProps, mapDispatchToProps)(Component)



