/*eslint no-extra-boolean-cast:0 */
import RENDER from '../trystup/trystup'
import renderDraftJS from '../trystup/trystup-to-draftjs'

const isFormatDirty = (f1, f2) => {
    if(f1.bg !== f2.bg) return true
    if(f1.fg !== f2.fg) return true
    if(f1.family !== f2.family) return true
    if(f1.fontSize !== f2.fontSize) return true
    if(f1.bisu !== f2.bisu) return true // potential bug if bisu are in the different orders here
    return false
}

const fgs = ['black', null, 'blue', 'green', 'red', 'gray']
const bgs = [null, 'yellow', 'powderblue', 'palegreen', 'mistyrose', 'lightgray']
const sizes = [null, 10, 13, 18, 24, 32]
const faces = ['serif', 'sans-serif', 'monospace']

class Line {
    constructor(id, trystup, format) {
        this._id = id
        this._trystup = trystup || ' '
        this._isItalic = false
        this._isUnderline = false
        this._isBold = false
        this._isStrikeout = false
        this._isLink = false
        this._family = 1
        this._fontSize = 2
        this._bg = 0
        this._fg = 0
        this._link = null
        this._imgLink = null
        this._marginBottom = null
        
        if(format) {
            this.bisu = format.bisu
            this.fg = format.fg
            this.bg = format.bg
            this.fontSize = format.fontSize
            this.family = format.family
        }
    }
    static escapeCharacters_callback(wholeMatch, m1) {
        const charCodeToEscape = m1.charCodeAt(0)
        return '~E' + charCodeToEscape + 'E'
    }
    static _UnescapeSpecialChars(text) {
        text = text.replace(/~E(\d+)E/g,
            function (wholeMatch, m1) {
                const charCodeToReplace = parseInt(m1)
                return String.fromCharCode(charCodeToReplace)
            })
        return text
    }

    get id() { return this._id }
    set id(value) { this._id = value }

    get trystup() { return this._trystup }
    set trystup(value) { this._trystup = value }

    get isItalic() { return this._isItalic }
    set isItalic(value) { this._isItalic = value }

    get isUnderline() { return this._isUnderline }
    set isUnderline(value) { this._isUnderline = value }

    get isBold() { return this._isBold }
    set isBold(value) { this._isBold = value }

    get isStrikeout() { return this._isStrikeout }
    set isStrikeout(value) { this._isStrikeout = value }

    get isLink() { return this._isLink }
    set isLink(value) { this._isLink = value }

    // fontSize: number = 2;
    // family: number = 1;
    // bg: number = 0;
    // fg: number = 0;

    get fontSize() { return this._fontSize }
    set fontSize(value) { this._fontSize = value }

    get family() { return this._family }
    set family(value) { this._family = value }

    get bg() { return this._bg }
    set bg(value) { this._bg = value }

    get fg() { return this._fg }
    set fg(value) { this._fg = value }

    get link() { return this._link }
    set link(value) { this._link = value }

    get imgLink() { return this._imgLink }
    set imgLink(value) { this._imgLink = value }

    get marginBottom() { return this._marginBottom }
    set marginBottom(value) { this._marginBottom = value }

    get bisu() {
        let result = ''
        if(this.isBold) result += 'b'
        if(this.isItalic) result += 'i'
        if(this.isUnderline) result += 'u'
        if(this.isStrikeout) result += 's'
        return result
    }
    set bisu(value) {
        value = value || ''
        this.isBold = /b/.test(value)
        this.isItalic = /i/.test(value)
        this.isUnderline = /u/.test(value)
        this.isStrikeout = /s/.test(value)
    }

    get format() {
        return {
            bisu:this.bisu,
            fg:this.fg,
            bg:this.bg,
            fontSize:this.fontSize,
            family:this.family
        }
    }

    clone(clonedLineId) {
        let newline = new Line(clonedLineId, this.trystup)
        newline.isItalic = this.isItalic
        newline.isUnderline = this.isUnderline
        newline.isStrikeout = this.isStrikeout
        newline.isBold = this.isBold
        newline.link = this.link
        newline.imgLink = this.imgLink
        newline.isLink = this.isLink
        newline.fontSize = this.fontSize
        newline.family = this.family
        newline.bg = this.bg
        newline.fg = this.fg
        return newline
    }

    packFormat() {
        var buis = []
        if (this.isBold) buis.push('B')
        if (this.isItalic) buis.push('I')
        if (this.isUnderline) buis.push('U')
        if (this.isStrikeout) buis.push('S')
        var bits = []
        function cleanColor(v)  {
            if (v === 0) return '0'
            if (!v || v < 0) return null
            return v.toString()
        }
        function cleanFace(v)  {
            if (v) return v.toString()
            if (v === 0) return '0'
            return null
        }
        bits[0] = buis.join('')
        bits[1] = cleanColor(this.fg)
        bits[2] = cleanColor(this.bg)
        bits[3] = cleanFace(this.family)
        bits[4] = this.fontSize ? this.fontSize.toString() : null
        bits[5] = this.marginBottom ? this.marginBottom.toString() : null
        return bits.join('-')
    }
    Pack() {
        var jo = {}
        jo.Id = this.id
        jo.C = this.trystup
        var format = this.packFormat()
        if (format) jo.F = format
        if (!!this.link) jo.X = this.link
        if (!!this.imgLink) jo.XI = this.imgLink
        return jo
    }

    toJson() {
        let result = {}
        if (this.isBold) result.isBold = true
        if (this.isItalic) result.isItalic = true
        if (this.isStrikeout) result.isStrikeout = true
        if (this.isUnderline) result.isUnderline = true
        if (this.fontSize) result.fontSize = this.fontSize
        if (this.family) result.family = this.family
        if (this.bg) result.bg = this.bg
        if (this.fg) result.fg = this.fg
        if (this.link) result.link = this.link
        if (this.imgLink) result.imgLink = this.imgLink
        if (this.marginBottom) result.marginBottom = this.marginBottom
        result.text = this.trystup
    }

    /**
     * @param {boolean} showFields - whether to show formulas or results in the output
     */
    toHtml(showFields=false, useStylesheets=true) {
        return RENDER(this.trystup, { showFields, useStylesheets, format: 'html' }) 
    }

    /**
     * @param {boolean} showFields - whether to show formulas or results in the output
     */
    toText(showFields=false) {
        return RENDER(this.trystup, {showFields, format:'text'}) 
    }
    
    toDraftJS() {
        return renderDraftJS(this.trystup)
    }
    
}
export {fgs, bgs, sizes, faces, isFormatDirty}
export default Line