import _ from 'lodash'
import Moment from 'moment'

import {renderFormatAsHtml} from './util'

// outputs classnames to represent inlinef formatting, such as fg1, bg2 etc.
// this makes it unsuitable for generating html for output....
// could we separate the rendering from the parsing?

// what to do about fields?
// they get displayed as #[=(BLAH)]
// we get to edit them as we like

const RGXLINK = {
  INTERNAL: /^#[a-z0-9]+$/i,
  TRYSTAL: /^trystal:/i,
  IMAGE: /\.(gif|png|jpe?g)$/i
}
const LINKTYPES = { OTHER:1, INTERNAL:2, TRYSTAL:3, IMAGE:4 }
const LINKTEST = (p, s) => _.startsWith(s, '#[(', p) && s.indexOf(') ', p+3) > 0

// testing for #[=(FORMULA)]
// s/b #[=FORMULA]

const FORMULATEST = (s, p) => _.startsWith(s, '#[=', p) && s.indexOf(']', p+3) >= 0
const FORMATTEST = (s,p) => {
  if(!_.startsWith(s, '#[', p)) return false
  p += 2
  let spacePos = s.indexOf(' ', p) - p
  switch(spacePos) {
    case 1: return 'bisu'.indexOf(s[p]) >= 0
    case 2:
      switch(s[p]) {
      case 's': return '12345'.indexOf(s[p+1]) >= 0
      case 'f': return '012'.indexOf(s[p+1]) >= 0
      default: return false
      }
    case 3:
      if(s[p+1] !== 'g') return false
      switch(s[p]) {
        case 'f': return ['0','2','3','4','5'].includes(s[p+2])
        case 'b': return ['0','1', '2','3','4','5'].includes(s[p+2])
        default: return false
      }
    default: return false;
  }
}

class Token {
    constructor(parent=null) {  
        this.parent = parent
        this.children = []
    }
    images() {
        let images = []
        this.children.forEach(token => images.push(...token.images()))
        return images
    }
    render(options) {
        return this.children.reduce((ACC, token) => ACC + token.render(options),'')
    }
}

class FormatToken extends Token {
    constructor(parent, str, p) {
        super(parent)
        this.format = str.substring(p, str.indexOf(' ', p)).toLowerCase()
    }
    render(options) {
        const content = this.children.map(TOKEN => TOKEN.render(options)).join('')
        switch(options.format) {
        case 'html': return renderFormatAsHtml(this.format, content, options) 
        case 'text': return content
        default: return content
        }
    }
}
class StrToken extends Token {
    constructor(parent, startPos=0) {
        super(parent)
        this.startPos = startPos
        this.endPos = startPos
        this.str = null
    }
    render(options) {
      if(options) {
        switch(options.format) {
          case 'draftjs': return _.escape(this.str)           
          case 'text': return this.str
          default: break
        }
      }
      return  _.escape(this.str)
    }
    close(str) {
        let s = str.substring(this.startPos, this.endPos + 1)
        s = s.replace(/\\([#\\\][])/g,'$1')  // convert escaped pound, backslash, and sqclose into the chars
        this.str = s
    }
}

class LinkToken extends Token {
    constructor(parent, str, pos) {
        super(parent)
        this.link = null
        this.linkType = null
        pos += 3
        let posEnd = str.indexOf(')', pos)
        let link = this.link = str.substring(pos, posEnd)
        if(RGXLINK.INTERNAL.test(link)) this.linkType = LINKTYPES.INTERNAL
        else if(RGXLINK.TRYSTAL.test(link)) this.linkType = LINKTYPES.TRYSTAL
        else if(RGXLINK.IMAGE.test(link)) this.linkType = LINKTYPES.IMAGE
        else this.linkType = LINKTYPES.OTHER
    }
    images() {
        let images = []
        if(this.linkType === LINKTYPES.IMAGE) images.push(this.link)
        this.children.forEach(token => images.push(...token.images()))
        return images
    } 
    // #[(abc)_
    render(options) {
        const {format} = options
        const {link, linkType, children} = this
        const content = children.map(token => token.render(options)).join('')
        if(format === 'text') return `[${content}](${link})`
        switch(linkType) {
          case LINKTYPES.INTERNAL: 
          case LINKTYPES.TRYSTAL: return `<a href='${link}'>${content}</a>`
          case LINKTYPES.IMAGE:
          case LINKTYPES.OTHER: return `<a target='_blank' href='${link}'>${content}</a>`
          default: break
        }
        return content
    }
}
class FormulaToken extends Token {
    constructor(parent, str, pos) {
        super(parent)
        pos += 3
        let posEnd = str.indexOf(']', pos)
        this.formula = str.substring(pos, posEnd)
    }
    // #[=abc_
    render(options) {
        let {showFields} = options
        if(showFields) return `<span class='CELL'>${this.formula}</span>`
        switch(this.formula.toUpperCase()) {
        case 'TODAY': return Moment().format('dddd, MMM Do')
        case 'NOW': return Moment().format('h:mma')
        default: return this.formula  
        }
    }
}
function tokenize(str) {
    const ROOT = new Token()
    const STRTOKENS = [] // keep a list of these so we can easily post process the content at the end
    const posMax = str.length
    let pos = 0, TOKEN = ROOT
    while(pos < posMax) {
        const oldpos = pos
        if(str[pos] === ']') {
            if(TOKEN instanceof StrToken) TOKEN = TOKEN.parent
            if(TOKEN.parent) TOKEN = TOKEN.parent
            pos++
        }
        else if(FORMATTEST(str, pos)) {
            if(TOKEN instanceof StrToken) TOKEN = TOKEN.parent
            const FORMAT = new FormatToken(TOKEN, str, pos+2)
            TOKEN.children.push(FORMAT)
            TOKEN = FORMAT
            pos += (FORMAT.format.length + 3)
        }
        else if(LINKTEST(pos, str)) {
            if(TOKEN instanceof StrToken) TOKEN = TOKEN.parent
            let LINK = new LinkToken(TOKEN, str, pos)
            TOKEN.children.push(LINK)
            TOKEN = LINK
            pos += LINK.link.length + 5
        }
        else if(FORMULATEST(str, pos)) {
            if(TOKEN instanceof StrToken) TOKEN = TOKEN.parent
            let FORMULA = new FormulaToken(TOKEN, str, pos)
            TOKEN.children.push(FORMULA)
            pos += FORMULA.formula.length + 4  // #[=TODAY]
        }
        else {
            if(!(TOKEN instanceof StrToken)) {
                let RAW = new StrToken(TOKEN, pos)
                TOKEN.children.push(RAW)
                TOKEN = RAW
                STRTOKENS.push(RAW)
            }
            let strToken = TOKEN
            if(str[pos] === '\\' && pos < posMax - 1) {
                strToken.endPos = pos + 1
                pos+=2
            } 
            else {
                strToken.endPos = pos
                pos++
            }
        }
        if(pos === oldpos) pos++
    }
    STRTOKENS.forEach(token => token.close(str))
    return ROOT
}

// let str = 'AB #[b CD] EF #[=(FORMULA)] #[(http://a.com/img.png) GH #[fg3 IJ] KL] MNO #[i PQR]'
// let ROOT = parse(str)
// let HTML = ROOT.render()
// let IMAGES = ROOT.images()

/**
 * Render trystup for output
 * @param {string} trystup - the content to render
 * @param options -- showFields, format(null:text)
 * @param {bool} options.showFields - show the formula or the output of a field 
 * @param {string} options.format - html (default) or text  
 * @returns {object} rendered plus imagelink
 */
function RENDER(trystup, options) {
    if(!options) options = {}
    if(!options.showFields) options.showFields = false
    if(!options.format) options.format = 'html'
    if(!options.useStylesheets) options.useStyleSheets = true
    const root = tokenize(trystup)
    const rendered = root.render(options)
    const imageLinks = root.images()
    return { rendered, imageLinks }
}

export default RENDER
