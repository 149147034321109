import {
  // Editor, getDefaultKeyBinding, RichUtils, 
  // convertToRaw, 
  CompositeDecorator,
  EditorState 
} from 'draft-js'
import Link  from './link'
import Field from './field'
import trystupToContentState  from '../../trystup/trystup-to-draftjs'
import { 
  // isPartialKey, styleMap,
  // getSimpleBinding, getCompoundBinding,
  // addFieldReducer, addLinkReducer, bgReducer, fgReducer, familyReducer, sizeReducer,
  findLinkEntities, findFieldEntities 
} from './util'

export function initEditorState(trystup='') {
  const {contentState} = trystupToContentState(trystup.trim()) 
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link
    },
    {
      strategy: findFieldEntities,
      component: Field
    }
  ])
  let editorState = EditorState.createWithContent(contentState, decorator)
  editorState = EditorState.moveSelectionToEnd(editorState) 
  return editorState
}
