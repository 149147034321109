import React from 'react';
import thunk from 'redux-thunk'
import {render} from 'react-dom';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux'
import MobileDetect from 'mobile-detect'
import reducer from './reducers'
import AppVTrist from './components/app-vtrist'
import Landing from './landing/landing'
import * as COOKIE from './cookies'
import { setInitialState } from './actions/actions'
import { 
  // ajaxLoadSubscriptions,
  // ajaxLoadFileList,
  // ajaxLoadTrist,
  // ajaxFlushDirtyTrists,
  // startSaveTimer,
  // fetchPublications
} from './actions/actions-thunk'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import * as serviceWorker from './serviceWorker';

const CheckWelcome = () => {
  const welcomeSeen = 'welcomeSeen'
  const showWelcome = !COOKIE.getCookie(welcomeSeen)
  if(showWelcome) COOKIE.setCookie(welcomeSeen)
  return showWelcome
}

const showWelcome = CheckWelcome()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

const whoami = async function() {  return {} }

whoami().then(({uid, username}) => {
  const isAnon = !uid
  store.dispatch(setInitialState({uid, username, isAnon, isMobile:false, showWelcome}))
})


const md = new MobileDetect(window.navigator.userAgent)
const moreProps = {
  isBot: md.is('bot'),
  isMobile: md.mobile()
}

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/_/:uid/:fn' render={(props)=><AppVTrist {...props} {...moreProps}/>}/>
        <Route component={Landing} />
      </Switch>
    </Router>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
