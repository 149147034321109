import {
  VisibleTristActions as VTA, 
  EditorActions as EA, 
  ReduxActions as RA 
} from '../constants' // '../../shared/constants'
import { EditorState, /* Entity, */ RichUtils, Modifier } from 'draft-js'
import { setStyle/*, sizeReducer*/, addLinkReducer } from '../components/editor/util'
import * as EDIT from '../components/editor/editor'  //'../libs/editor.js'

const { toggleInlineStyle } = RichUtils

const defaultState ={tristUrl:null, editorState:null}

function addField(editorState, formula) {
  const cstate = editorState.getCurrentContent().createEntity('FIELD','IMMUTABLE',{formula})
  const key = cstate.getLastCreatedEntityKey()
  const cstate1 = Modifier.insertText(cstate, editorState.getSelection(), formula, null, key)
  return EditorState.push(editorState, cstate1, 'apply-entity')
}
function EDITORSTATE(editorState, action) {
  switch(action.editorActionType) {
    //case EA.DO_SOMETHING: return sizeReducer(editorState, 'S5')
    case EA.TOGGLE_STYLE: return toggleInlineStyle(editorState, action.style)
    case EA.SET_STYLE:    return setStyle(editorState, action.style)
    case EA.ADD_LINK:     return addLinkReducer(editorState, action.url)
    case EA.ADD_FIELD:    return addField(editorState, action.formula)
    case EA.UNDO:         return EditorState.undo(editorState)
    case EA.REDO:         return EditorState.redo(editorState)
    default: return editorState
  }
}
function processEditorAction(editor, action) {
  const editorState = EDITORSTATE(editor.editorState, action)
  if(editorState === editor.editorState) return editor
  return { tristUrl:editor.tristUrl, editorState } 
}
function edit(state, action) {
  const {tristUrl} = action
  const vtrist = state.vtrists.find(vt => tristUrl === vt.tristUrl)
  const editorState = EDIT.initEditorState(vtrist.trist.focus.payload.trystup)
  return {tristUrl, editorState}
}
function EDITOR(state, action) {
  // if(state.appvars.isMobile) return state.editor
  const editor = state.editor || defaultState
  switch(action.type) {
    case RA.VTRIST_ACTION:
      switch(action.vtristActionType) {
        case VTA.EDIT:         return edit(state, action)
        default:               return editor            
      }  
    case RA.SET_EDIT_STATE: return {tristUrl: editor.tristUrl, editorState: action.editorState}
    case RA.EDITOR_ACTION:  return processEditorAction(editor, action)
    default: return editor 
  }       
}

export default EDITOR