import { ReduxActions as RA } from '../constants'
import { PageTypes }          from '../util/pagetypes'

const TIMELINEPAGE = { pageType: PageTypes.TIMELINE } 
const FILEPICKER  = { pageType: PageTypes.FILEPICKER }
const TRISTPAGE = tristUrl => ({ pageType: PageTypes.VTRIST, tristUrl}) 

export default (state, action) => {
  const activePage = state.activePage || TIMELINEPAGE
  switch(action.type) {
    case RA.SPECIAL:         return TIMELINEPAGE
    case RA.FILEPICKER_SHOW: return FILEPICKER
    case RA.ACTIVATE_VTRIST: return TRISTPAGE(action.tristUrl)
    default: return activePage
  }
}
