import React from 'react'
import { Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import VisibleTrist      from './visible-trist'
import TopNav            from './topnav/topnav'
import { TryUrl }        from '../util/tryurl'
import * as THUNKS from '../actions/actions-thunk'
//import { dlgFindShow }   from '../actions/actions'
// import { keyCodeOf, KeyCodes as KC } from '@trystal/keys'
import FindDialog         from './dialogs/find'

const buildTristProps = tristUrl => ({
  tristUrl,
  mode : null,
  format : null,
  isFocused : null,
  onClose : null,
  onShare : null,
  onPublish : null
})

const PUBLISHED = 2

const LeftThings = ({isMobile}) => {
  if(isMobile) return null
  return <Menu id='left-nav' vertical fixed='left'>
    {/* <Menu.Item name='Latest Files' as='a' href='/'/> */}
  </Menu>
}  


class AppVTrist extends React.Component{
  componentDidMount() {
    const {dispatch, match, isBot} = this.props
    const {uid, fn} = match.params
    const url = new TryUrl(uid, fn, PUBLISHED)
    dispatch(THUNKS.ajaxLoadTrist(url, isBot))
  }
  render() {
    const {tristUrl, vtrists, isMobile} = this.props
    const vtrist = vtrists.find(vt => vt.tristUrl === tristUrl)
    return (
    <div id='app-container'> 
      <TopNav />
         <LeftThings  {...{isMobile}}/>
         {
           vtrists.map(vt => {
             const visible = vt === vtrist
             const {tristUrl} = vt
             const key = tristUrl
             return <VisibleTrist key={key} hidden={!visible} {...buildTristProps(tristUrl, this.props)} />
           })                
         }
         <FindDialog tristUrl={tristUrl} />
       </div>
    )

  }
}

// const showFindDialog = (dispatch, tristId) => {
//   dispatch(dlgFindShow(tristId))
// }

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onKeyDown       : (e, tristId/*, vtrists*/) => {} //onKeyDown(dispatch, e, tristId /*, vtrists*/)
  }
}
const mapStateToProps = (state, props) => {
  const {activePage:{tristUrl}, vtrists} = state
  return { vtrists, tristUrl }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppVTrist)
