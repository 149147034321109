import { ReduxActions as RA } from '../constants'

const setTimeline = (oldTimeline, {timeline}) => {
  const images = ["joe","matt","jenny","elliot"]
  const avatars = new Map()
  timeline.forEach(item => {
    const {uid} = item
    if(!avatars.has(uid)) avatars.set(uid, `/img/${images[avatars.size % 4]}.jpg`)
    item.avatar = avatars.get(uid)
  });
  return timeline
}

export default (state, action) => {
  const timeline = state.timeline || []
  switch(action.type) {
    case RA.SET_TIMELINE: return setTimeline(timeline, action)
    default: return timeline
  }
}
