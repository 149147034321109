import React from 'react'
import { connect } from 'react-redux'
import keycode from 'keycode'
import VisibleLine from './visible-line'
import Carousel    from './carousel'
import Toolbar from './toolbars/desk-toolbar-sem'
import * as actions from '../actions/actions'

const buildToolbarProps = ({ mode, vtrist, isLineFocused, format, onAdd, onEdit }) => ({
  tristUrl: vtrist.tristUrl, 
  mode,  isLineFocused, format,  onAdd, onEdit
})

const toAction = (e) => {
  const {altKey, shiftKey, ctrlKey/*, metaKey*/} = e
  const key = keycode(e)
  if(altKey && shiftKey && ctrlKey) return null
  if(altKey && shiftKey) return null
  if(altKey && ctrlKey) return null
  if(shiftKey && ctrlKey) return null
  if(altKey) return null
  if(ctrlKey) {
    switch(key) {
      case 'up': return 'qojg';
      case 'down': return 'qojh';
      case 'right': return 'mvrt'
      case 'left': return 'mvlt'
      case 'z': return 'undo'
      case 'y': return 'redo'
      default: 
        console.log(key)
        return null;
    }
  }
  if(shiftKey) {
    switch(key) {
      case 'up': return 'qoiy'
      case 'down': return 'qoix'
      default: return null
    }
  }
  switch(key) {
    case 'up': return 'up'
    case 'down': return 'down'
    case 'home': return 'home'
    case 'end': return 'end'
    case 'left': return 'left'
    case 'right': return 'rght'
    default: console.log(key); return null
  }
}
const processAction = (e, dispatch, vtrist) => {
  const action = toAction(e)
  if(!action) return
  e.preventDefault()
  e.stopPropagation()
  const {tristUrl} = vtrist
  switch(action) {
    case 'up':   return dispatch(actions.lineUp(tristUrl))
    case 'down': return dispatch(actions.lineDown(tristUrl))
    case 'home': return dispatch(actions.lineHome(tristUrl))
    case 'end': return dispatch(actions.lineEnd(tristUrl))
    case 'qoix': return dispatch(actions.extendDown(tristUrl))
    case 'qoiy': return dispatch(actions.extendUp(tristUrl))
    case 'left': return dispatch(actions.lineLeft(tristUrl))
    case 'rght': return dispatch(actions.lineRight(tristUrl))
    case 'undo': return dispatch(actions.undo(tristUrl))
    case 'redo': return dispatch(actions.redo(tristUrl))
    default: return;
  }
}

class VisibleTrist extends React.Component {
  componentWillUpdate(nextProps) {
    const OLD = this.props.vtrist
    const NEW = nextProps.vtrist
    if(OLD === NEW || !OLD.focusId || !NEW.focusId) return
    const oldLine = OLD.vlines.find(line => line.id === OLD.focusId)
    if(NEW.focusId === oldLine.navigation.down) this.shouldScroll = true
    else if(this.shouldScroll) this.shouldScroll = false
  }
  componentDidUpdate() {
    if(!this.shouldScroll) return
    this.shouldScroll = false
    const scroller = document.getElementById('scroller')
    const lineElem = document.getElementById(this.props.vtrist.focusId)
    const topNow = lineElem.offsetTop - scroller.scrollTop
    const limit = scroller.clientHeight / 4 * 3
    if(topNow > limit) scroller.scrollTop += topNow - limit
  }
  onKeyDown(e) {
    const {dispatch, vtrist} = this.props
    return processAction(e, dispatch, vtrist)
  }
  render() {
    if(this.props.hidden) return null;
    const {vtrist:{tristUrl, vlines}} = this.props
    var tbprops = buildToolbarProps(this.props)
    return (<div className='visible-trist-container' tabIndex={0} ref='vtrist' onKeyDown={this.onKeyDown.bind(this) }>
      <Carousel {...{ tristUrl }}/>
      <Toolbar {...tbprops} />
      <div id='scroller'>
        { vlines.map(({id}) => <VisibleLine key={id} tristUrl={tristUrl} lineId={id} />) }
        <div id='spacer'/>
      </div>
    </div>)
  }
}

const mapStateToProps = (state, props) => {
  const {tristUrl} = props
  const vtrist = state.vtrists.find(vt => vt.tristUrl === tristUrl)
  const {activePage:{tristUrl:activeTristUrl}} = state
  const visible = tristUrl === activeTristUrl
  if(!visible) return {
    hidden:true,
    vtrist, 
    isLineFocused:false, 
    mode:null
  }
  const {focusId} = vtrist
  const isLineFocused = !!focusId
  const mode = 'RO'
  return { hidden:false, vtrist, isLineFocused, mode }
}


function mapDispatchToProps(dispatch) {
  return { 
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibleTrist)

