import {each, difference, filter, find, intersection, keyBy, last, map, without} from 'lodash'

import Node from '@trystal/tryst/trist-node'
import Trist from '@trystal/tryst/trist'
import Line  from './line'

import {Formats} from './linelib'
import {buildFormatString} from './REV2014'

const attrs = { B: 'isBold', I: 'isItalic', U: 'isUnderline', S: 'isStrikeout' }
const Q = ['0', '1', '2', '4', '8', '16']

/**
    Save to json, computing revisions by comparing to the earlier version of the document.
*/
function buildPayload(content, format) {
    const line = new Line(content.id, content.text)
    line.link = content.link
    line.isLink = !!line.link
    line.imgLink = content.imgLink

    if(format) {
        const fbits = format.split('-')
        each(fbits[0], function (flag) { line[attrs[flag]] = true })
        if (fbits[1]) { line.fg = Q.indexOf(fbits[1]); if (line.fg < 0) line.fg = 0 }
        if (fbits[2]) { line.bg = Q.indexOf(fbits[2]); if (line.bg < 0) line.bg = 0 }
        if (fbits[3]) line.family = parseInt(fbits[3])
        if (fbits[4]) line.fontSize = parseInt(fbits[4])
        if (fbits[5]) line.marginBottom = parseFloat(fbits[5])
    }
    return line
}
export function checkIntegrity(trist) {
    // only does some limited checking of end and start nodes for the moment
    // does no level checking at all
    // only considers the activeState trist, not any of the revisions
    if(trist.length === 0) return true
    let nodes = trist.toArray()                          // N[ABCD]
    let ids = map(nodes, N => N.id)                  // ABCD
    let endNodes = nodes.filter(N => !N.next)       // N[D]
    if(endNodes.length !== 1) return false
    let nodesHavingNext = difference(nodes, endNodes)  // N[ABC]
    let nexts  = map(nodesHavingNext, 'next') // N[BCD]
    let nextIds = nexts.map(N => N.id)              // BCD
    let firsts = difference(ids, nextIds)              // (ABCD, BCD) => A
    if(firsts.length !== 1) return false
    return true
}
export function revise(svrTrist, trist, authorId) {
    function validateServerTrist(serverTrist) {
        if (!serverTrist) {
            serverTrist = {
                version: Formats.FMT2015,
                map: [],
                contents: [],
                revisions: []
            }
        }
        return serverTrist
    }
    const CLIENT = {
        trist: trist,
        tristNodes: trist.toArray(),
        ids:null, // HHH
        nodeIndex:null
    }
    CLIENT.ids = map(CLIENT.tristNodes, H => H.id) // BCD
    CLIENT.nodeIndex = keyBy(CLIENT.tristNodes, H => H.id)           // [BCD->HHH]
    const SERVER = {
        trist:validateServerTrist(svrTrist),
        activeNodes:null,
        ids:null,
        contentIndex:null,
        mapIndex:null
    }
    SERVER.activeNodes = filter(SERVER.trist.map, { isDeleted: false }) // NNN
    SERVER.ids = map(SERVER.activeNodes, 'id')                       // ABC // this is wrong -- it needs to exclude items that have been deleted via previous revisions
    SERVER.contentIndex = keyBy(SERVER.trist.contents, 'id') // [ABC->CCC]
    SERVER.mapIndex = keyBy(SERVER.activeNodes, 'id')           // [ABC->NNN]
    const common = intersection(CLIENT.ids, SERVER.ids)           // (BCD,ABC) => BC
    const revision = {
        authorId: authorId,
        date: new Date(),
        adds: difference(CLIENT.ids, common),  // (BCD,BC) => D
        dels: difference(SERVER.ids, common),  // (ABC,BC) => A
        edits: []
    }
    each(revision.adds, id => { // D
        // push in the new content
        const clientNode = CLIENT.nodeIndex[id]
        const payload = clientNode.payload
        const content = {
            id: id,
            text: payload.trystup,
            link: payload.link,
            imgLink: payload.imgLink
        }
        SERVER.trist.contents.push(content)
        // add a new mapnode
        let mapnode = {
            id: clientNode.id, // D
            format: buildFormatString(clientNode.payload),
            rlevel: clientNode.rlevel,
            next: clientNode.next ? clientNode.next.id : '',
            vnext: clientNode.NV ? clientNode.NV.id : '',
            isDeleted: false
        }
        SERVER.trist.map.push(mapnode)
    })
    function diff_wordMode(/*text1, text2*/) {
        return null
        /*let dmp = new diff_match_patch()
        let a = dmp.diff_linesToWords_(text1, text2)
        let lineText1 = a.chars1
        let lineText2 = a.chars2
        let lineArray = a.lineArray
        let diffs = dmp.diff_main(lineText1, lineText2, false)
        dmp.diff_charsToLines_(diffs, lineArray)
        return diffs;*/
    }
    // function patch_wordMode(/*text1, text2*/) {
    //     return null
    //     /*let dmp = new diff_match_patch()

    //     let a = dmp.diff_linesToWords_(text1, text2)
    //     let lineText1 = a.chars1
    //     let lineText2 = a.chars2
    //     let lineArray = a.lineArray
    //     let diffs = dmp.diff_main(lineText1, lineText2, false)
    //     dmp.diff_charsToLines_(diffs, lineArray)
    //     return diffs;*/
    // }
    each(common, id => {  // BC
        // put in the content
        let oldItem = SERVER.contentIndex[id]
        let node = CLIENT.nodeIndex[id]
        let payload = node.payload
        if(oldItem.link !== payload.link) oldItem.link = payload.link
        if(oldItem.imgLink !== payload.imgLink) oldItem.imgLink = payload.imgLink
        if (oldItem.text !== payload.trystup) {
            let usePatch = (oldItem.text.length > 5 || payload.trystup.length > 5)
            usePatch = false // change this when we're confident of the diff strategy
            let edit = {
                lineId: id,
                delta: usePatch ? diff_wordMode(payload.trystup, oldItem.text) : oldItem.text,
                isPatch : usePatch
            }
            revision.edits.push(edit)
            oldItem.text = payload.trystup
        }
        // push in the map changes, if any
        let maprec = SERVER.mapIndex[id]
        maprec.format = buildFormatString(node.payload)
        maprec.rlevel = node.rlevel
        maprec.next = node.next ? node.next.id : ''
        maprec.vnext = node.NV ? node.NV.id : ''
    })
    each(revision.dels, id => SERVER.mapIndex[id].isDeleted = true)
    SERVER.trist.revisions.push(revision)
    return SERVER.trist
}

export function fromJSON(serverTrist) {
    function buildTrist(serverNodes, buildNODE) {
        function buildNodes(serverNodes, buildNODE) {
            // index the server nodes by id
            let serverNodesById = keyBy(serverNodes, 'id')
            // [S1] id='S1', content='hello', next=S2
            // [S2] id='S2', content='there', next=S3
            // [S3] id='S3', content='how', next=S4
            // [S4] id='S4', content='are', next=s5
            // [S5] id='S5', content='you' next=NULL
            let serverNode = find(serverNodes, N => !serverNodesById[N.next])
            // S5 = 'you' next=NULL
            let serverNodesByNext = keyBy(without(serverNodes, serverNode), 'next')
            // [S2] id='S1', content='hello', next=S2
            // [S3] id='S2', content='there', next=S3
            // [S4] id='S3', content='how', next=S4
            // [S5] id='S4', content='are', next=s5
            let tristNodes = []
            while (serverNode) {
                // 1: {id='S5', content='you', next=null}
                // 2: {id='S4', content='are', next=S5}
                let newNode = buildNODE(serverNode)
                // 1: {id:'S5', rlevel=0, content='you'}
                // 2: {id:'S5', rlevel=0, content='you'}
                // 2: {id:'S4', rlevel=0, content='are'}
                tristNodes.push(newNode)
                serverNode = serverNodesByNext[serverNode.id]
                // 1: {id='S4', content='are', next=S5}
                // 2: {id='S34', content='how', next=S4}
            }
            // {id:'S5', rlevel=0, content='you'}
            // {id:'S4', rlevel=0, content='are'}
            // {id:'S3', rlevel=0, content='how'}
            // {id:'S4', rlevel=0, content='there'}
            // {id:'S5', rlevel=0, content='hello'}
            return tristNodes
        }

        function connectNodes(tristNodes, serverNodes) {
            // server nodes
            // { id='S1', content='hello', next=S2 }
            // { id='S2', content='there', next=S3 }
            // { id='S3', content='how', next=S4 }
            // { id='S4', content='are', next=s5 }
            // { id='S5', content='you' next=NULL }

            // trist nodes
            // {id:'S5', rlevel=0, content='you'}
            // {id:'S4', rlevel=0, content='are'}
            // {id:'S3', rlevel=0, content='how'}
            // {id:'S4', rlevel=0, content='there'}
            // {id:'S5', rlevel=0, content='hello'}
            let tristNodeIndex = keyBy(tristNodes, N => N.id)
            // [S5] {id:'S5', rlevel=0, content='you'}
            // [S4] {id:'S4', rlevel=0, content='are'}
            // [S3] {id:'S3', rlevel=0, content='how'}
            // [S2] {id:'S4', rlevel=0, content='there'}
            // [S1] {id:'S5', rlevel=0, content='hello'}
            function connectOne(serverNode) {
                let tristNode = tristNodeIndex[serverNode.id]
                if (serverNode.next) {
                    tristNode.next = tristNodeIndex[serverNode.next]
                    if(tristNode.next) tristNode.next.prev = tristNode
                }
                if (serverNode.vnext) {
                    tristNode.NV = tristNodeIndex[serverNode.vnext]
                    if(tristNode.NV) tristNode.NV.PV = tristNode
                }
            }
            let serverNodeIndex = keyBy(serverNodes, 'id')
            let serverNode = serverNodeIndex[last(tristNodes).id]
            while (serverNode) {
                connectOne(serverNode)
                serverNode = serverNodeIndex[serverNode.next]
            }
        }

        let tristNodes = buildNodes(serverNodes, buildNODE)
        connectNodes(tristNodes, serverNodes)
        let trist = new Trist()
        trist.nodes = tristNodes
        trist.first = last(tristNodes)
        return trist
    }
    let contents = serverTrist.contents
    if (!contents || contents.length === 0) return new Trist()
    let contentIndex = keyBy(contents, 'id')
    function buildTristNode(serverNode) {
        const content = contentIndex[serverNode.id]
        const format = serverNode.format
        const payload = buildPayload(content, format)
        const tristNode = new Node(payload)
        tristNode.rlevel = serverNode.rlevel
        return tristNode
    }
    let nodes = filter(serverTrist.map, { isDeleted: false })
    // let trists : TristModule.Trist[] = []
    let trists = []
    let trist = buildTrist(nodes, buildTristNode)
    trists.push(trist)
    // here we try to handle some bad content from an invalid trist
    while(trist.length < nodes.length) {
        let tristIds = map(trist.toArray(false), N => N.id)
        let nodeIds = map(nodes, 'id')
        let remaining = difference(nodeIds, tristIds)
        let nodeIndex = keyBy(nodes, 'id')
        nodes = map(remaining, id => nodeIndex[id])
        trist = buildTrist(nodes, buildTristNode)
        trists.push(trist)
    }
    let result = trists[0]
    for(let i = 1, j=trists.length; i < j; i++) result.paste(trists[i], 0)
    return result
}
